import { ReduxRouterState, push } from '@lagunovsky/redux-react-router';
import { PayloadAction } from '@reduxjs/toolkit';
import { logEvent } from 'firebase/analytics';
import {
  AuthError,
  GoogleAuthProvider,
  UserCredential,
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import ky, { HTTPError } from 'ky';
import { put, select, take } from 'redux-saga/effects';
import { getApi } from '../../helpers/api';
import { AuthErrorCode, analytics, auth } from '../../helpers/firebase';
import { getPixRate } from '../../helpers/pix-rate';
import {
  getCurrentPathnameForData,
  getStepsForPath,
} from '../../helpers/steps';
import { upload } from '../../helpers/upload';
import {
  AppState,
  AuthenticatedUser,
  CheckPix,
  CompleteSignUpData,
  DBEnterprise,
  DBUser,
  FormCompanySetup,
  FormConfirmEmail,
  FormConfirmPassword,
  FormEditUser,
  FormEmailForgotPassword,
  FormEmailLogin,
  FormPixData,
  FormSignUp,
  GetDownloadLinkResponse,
  PixAccountData,
  SignupMethod,
} from '../../types';
import { notifierActions } from '../notifier/notifier-slice';
import { appActions } from './app-slice';

const pause = async (timeout: number) =>
  new Promise((res) => setTimeout(res, timeout));

function* watchErrors() {
  while (true) {
    const { payload: message } = yield take(appActions.setError);
    yield put(
      notifierActions.enqueue({
        message,
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* watchSignUpWithEmail() {
  while (true) {
    const { payload: data }: PayloadAction<FormSignUp> = yield take(
      appActions.signUpWithEmail
    );

    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );

    try {
      yield put(appActions.startLoading());
      yield put(appActions.setIsSigningUp(true));
      const { email, password } = data;
      const credential: UserCredential = yield createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = credential;
      const authenticatedUser: AuthenticatedUser = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        uid: user.uid,
        photoUrl: user.photoURL ?? undefined,
        provider: 'email',
      };
      yield put(appActions.setAuthenticatedUser(authenticatedUser));
      const signUpData: CompleteSignUpData = {
        method: data.method,
        displayName: authenticatedUser.displayName ?? data.displayName,
        email: authenticatedUser.email ?? data.email,
        phoneNumber: data.phoneNumber,
        uid: authenticatedUser.uid,
        emailVerified: authenticatedUser.emailVerified,
        photoUrl: authenticatedUser.photoUrl,
      };

      const { url, headers, ...opts } = yield getApi({
        path: '/onboarding/signup',
      });

      const origin = sessionStorage.getItem('br.com.zetaone.origin');
      const plan = sessionStorage.getItem('br.com.zetaone.plan');

      const response: DBUser = yield ky(url, {
        method: 'post',
        json: { origin, plan, ...signUpData, location },
        headers,
        ...opts,
      }).json();

      // console.log('BackEnd API response: ', { info: { response } });
      // console.log('Current user: ', auth.currentUser);

      // logEvent(analytics, 'sign_up', { method: data.method });
      console.log('GA 1_sign_up');
      logEvent(analytics, '1_sign_up', { method: data.method });

      if (!auth.currentUser) {
        yield put(push({ ...location, pathname: '/login' }));
        yield put(appActions.setMaxStep(getStepsForPath('/login')));
      } else {
        yield put(push({ ...location, pathname: '/company-setup' }));
        yield put(appActions.setMaxStep(getStepsForPath('/company-setup')));
      }
      yield put(appActions.finishLoading());
      yield put(appActions.setDBUser(response));
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao registrar usuário';
      console.error(code);
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/weak-password':
          message = 'A senha é muito fraca';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        default:
          message = 'Erro registrando usuário';
          break;
      }
      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSignUpWithAuthProvider() {
  while (true) {
    const { payload: method }: PayloadAction<SignupMethod> = yield take(
      appActions.signUpWithAuthProvider
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );

    try {
      yield put(appActions.startLoading());
      yield put(appActions.setIsSigningUp(true));
      const provider = new GoogleAuthProvider();
      provider.addScope('https://www.googleapis.com/auth/userinfo.email');
      provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
      const credential: UserCredential = yield signInWithPopup(auth, provider);
      const { user } = credential;
      console.log('returned user: ', user);
      const authenticatedUser: AuthenticatedUser = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        uid: user.uid,
        photoUrl: user.photoURL ?? undefined,
        provider: 'google',
      };
      const { url, headers, ...opts } = yield getApi({
        path: '/onboarding/signup',
      });

      const origin = sessionStorage.getItem('br.com.zetaone.origin');
      const plan = sessionStorage.getItem('br.com.zetaone.plan');

      const signUpPartialData: CompleteSignUpData = {
        method,
        displayName: user.displayName ?? undefined,
        email: user.email ?? undefined,
        uid: user.uid,
        emailVerified: user.emailVerified,
        photoUrl: user.photoURL ?? undefined,
      };

      const response: DBUser = yield ky(url, {
        method: 'post',
        json: { origin, plan, ...signUpPartialData, location },
        headers,
        ...opts,
      }).json();

      // console.log('BackEnd API response: ', { info: { response } });
      // console.log('Current user: ', auth.currentUser);

      console.log('GA 1_sign_up');
      logEvent(analytics, '1_sign_up', { method });
      yield put(appActions.setAuthenticatedUser(authenticatedUser));
      yield put(appActions.setSignupFormType(method));
      yield put(appActions.setDBUser(response));
      yield put(appActions.finishLoading());
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao registrar usuário';
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/weak-password':
          message = 'A senha é muito fraca';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        case 'auth/popup-closed-by-user':
          message =
            'Erro ao registrar usuário, por favor tente novamente em alguns instantes!';
          return;
      }

      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoginWithEmailAndPassword() {
  while (true) {
    const { payload: data }: PayloadAction<FormEmailLogin> = yield take(
      appActions.loginWithEmailAndPassword
    );
    const { email, password } = data;

    try {
      yield put(appActions.startLoading());

      const credential: UserCredential = yield signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = credential;
      const authenticatedUser: AuthenticatedUser = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        uid: user.uid,
        photoUrl: user.photoURL ?? undefined,
        provider: 'email',
      };
      yield put(appActions.setAuthenticatedUser(authenticatedUser));
      yield put(appActions.completeLogin(authenticatedUser));
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao fazer login';
      console.error(code);
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/wrong-password': {
          const providers: string[] = yield fetchSignInMethodsForEmail(
            auth,
            email
          );
          if (providers.includes('password')) message = 'Senha incorreta';
          else
            message =
              'Esse e-mail está registrado, utilize a opção Entrar com o Google';
          break;
        }
        case 'auth/user-not-found':
          message = 'E-mail não encontrado';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        default:
          message = 'Erro';
          break;
      }
      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchLoginWithAuthProvider() {
  while (true) {
    const { payload: method }: PayloadAction<SignupMethod> = yield take(
      appActions.loginWithAuthProvider
    );

    try {
      yield put(appActions.startLoading());
      const credential: UserCredential = yield signInWithPopup(
        auth,
        new GoogleAuthProvider()
      );
      const { user } = credential;
      const authenticatedUser: AuthenticatedUser = {
        displayName: user.displayName,
        email: user.email,
        emailVerified: user.emailVerified,
        uid: user.uid,
        photoUrl: user.photoURL ?? undefined,
        provider: 'google',
      };
      yield put(appActions.setAuthenticatedUser(authenticatedUser));
      yield put(appActions.completeLogin(authenticatedUser));
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao fazer login';
      console.error(code);
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/weak-password':
          message = 'A senha é muito fraca';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        case 'auth/popup-closed-by-user':
          message =
            'Erro ao registrar usuário, por favor tente novamente em alguns instantes!';
          break;
      }

      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchResumeLogin() {
  while (true) {
    const { payload: authenticatedUser }: PayloadAction<AuthenticatedUser> =
      yield take(appActions.resumeLogin);

    try {
      const { isSigningUp }: AppState = yield select((state) => state.app);
      if (isSigningUp) {
        return;
      }
      yield put(appActions.startLoading());
      yield put(appActions.setAuthenticatedUser(authenticatedUser));
      yield put(appActions.completeLogin(authenticatedUser));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
      yield put(appActions.setIsInitializing(false));
    }
  }
}

function* watchSendPasswordResetEmail() {
  while (true) {
    const { payload: data }: PayloadAction<FormEmailForgotPassword> =
      yield take(appActions.sendPasswordResetEmail);
    const { email } = data;

    try {
      yield put(appActions.startLoading());
      const providers: string[] = yield fetchSignInMethodsForEmail(auth, email);
      if (!providers.includes('password')) {
        yield put(
          appActions.setError(
            'Não é possível reiniciar a senha, caso tenha se registrado com o Google utilize a opção Entrar com o Google'
          )
        );
        yield put(appActions.finishLoading());
      } else {
        yield sendPasswordResetEmail(auth, email);
        yield put(appActions.setAwaitingPasswordReset(true));
        yield put(appActions.finishLoading());
      }
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao fazer login';
      console.error(code);
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/user-not-found':
          message = 'E-mail não encontrado';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        default:
          message = 'Erro registrando usuário';
          break;
      }
      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchConfirmPassword() {
  while (true) {
    const { payload: data }: PayloadAction<FormConfirmPassword> = yield take(
      appActions.confirmPassword
    );
    const { password, oobCode } = data;

    try {
      yield put(appActions.startLoading());
      yield confirmPasswordReset(auth, oobCode, password);
      yield put(appActions.setConfirmedPassword(true));
      yield put(appActions.finishLoading());
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao fazer login';
      console.error(code);
      switch (code) {
        case 'auth/email-already-in-use':
          message = 'Esse e-mail já foi registrado na ZetaOne';
          break;
        case 'auth/invalid-email':
          message = 'E-mail inválido';
          break;
        case 'auth/operation-not-allowed':
          message = 'Erro ao registrar usuário';
          break;
        case 'auth/user-not-found':
          message = 'E-mail não encontrado';
          break;
        case 'auth/too-many-requests':
          message = 'Por favor tente novamente mais tarde';
          break;
        default:
          message = 'Erro registrando usuário';
          break;
      }
      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchConfirmEmail() {
  while (true) {
    const { payload: data }: PayloadAction<FormConfirmEmail> = yield take(
      appActions.confirmEmail
    );
    const { oobCode } = data;

    try {
      yield put(appActions.startLoading());
      yield applyActionCode(auth, oobCode);
      yield put(appActions.setConfirmedEmail(true));
      yield put(appActions.finishLoading());
    } catch (e) {
      const code = (e as AuthError).code as AuthErrorCode;
      let message: string = 'Erro ao confirmar e-mail';
      console.error(code);
      if (code !== 'auth/invalid-action-code')
        yield put(appActions.setError(message));
      else yield put(appActions.setConfirmedEmail(true));
      yield put(appActions.finishLoading());
    }
  }
}

/**
 * 1. Login/signup
 * 2. Company setup
 * 3. Pix setup
 * 4. Account setup
 * 5. E-mail validation
 */
function* watchCompleteLogin() {
  while (true) {
    const { payload }: PayloadAction<AuthenticatedUser> = yield take(
      appActions.completeLogin
    );
    try {
      yield put(appActions.startLoading());
      const { uid } = payload;
      const { url, headers, ...opts } = yield getApi({
        path: `/user/${uid}`,
      });

      const dbUser: DBUser = yield ky(url, {
        method: 'get',
        headers,
        ...opts,
      }).json();

      let dbEnterprise: DBEnterprise | undefined;

      if (!dbUser.emailVerified && payload.emailVerified) {
        yield ky(url, {
          method: 'patch',
          json: { emailVerified: payload.emailVerified },
          headers,
          ...opts,
        });
        dbUser.emailVerified = true;

        const {
          url: url2,
          headers: headers2,
          ...opts2
        } = yield getApi({
          path: `/onboarding/validate-email`,
        });
        yield ky(url2, {
          method: 'POST',
          json: { id: dbUser.enterpriseId },
          headers: headers2,
          ...opts2,
        }).json();
      }
      if (!dbUser.photoUrl && payload.photoUrl) {
        yield ky(url, {
          method: 'patch',
          json: { photoUrl: payload.photoUrl },
          headers,
          ...opts,
        });
        dbUser.photoUrl = payload.photoUrl;
      }

      yield put(appActions.setDBUser(dbUser));
      if (!auth.currentUser) {
        yield put(push({ ...dbUser.location, pathname: '/login' }));
        yield put(appActions.setMaxStep(getStepsForPath('/login')));
        yield put(appActions.setMaxStep(0));
        yield put(appActions.finishLoading());
        yield put(appActions.setIsInitializing(false));
        continue;
      }
      if (!dbUser.phoneNumber) {
        yield put(push({ ...dbUser.location, pathname: '/' }));
        yield put(appActions.setMaxStep(getStepsForPath('/')));
        yield put(appActions.finishLoading());
        yield put(appActions.setIsInitializing(false));
        continue;
      }
      const eid = dbUser.enterpriseId;
      if (eid) {
        const { url, headers, ...opts } = yield getApi({
          path: `/enterprise/${eid}`,
        });
        dbEnterprise = yield ky(url, {
          method: 'get',
          headers,
          ...opts,
        }).json();
        yield put(appActions.setDBEnterprise(dbEnterprise));
      }

      const pathname = getCurrentPathnameForData(dbUser, dbEnterprise);

      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
      yield put(push({ ...dbUser.location, pathname }));
      yield put(appActions.finishLoading());
      yield put(appActions.setIsInitializing(false));
    } catch (e) {
      console.error(e);

      let message = 'Erro ao registrar usuário';
      if (e instanceof HTTPError) {
        const httpError = e as HTTPError;
        switch (httpError.response.status) {
          case 404:
            if (payload.provider === 'google') {
              yield put(appActions.setSignupFormType('google'));
              yield put(appActions.setAuthProvider('google'));
              yield put(appActions.finishLoading());
              yield put(appActions.setIsInitializing(false));
              yield put(push('/'));
              continue;
            }
            message = 'Usuário não encontrado';
            break;
          case 500:
            message = 'Erro ao registrar usuário';
            break;
          default: {
            const body: unknown = yield httpError.response.json();
            message = (body as any).error ?? 'Erro ao registrar usuário';
          }
        }
      }

      yield put(appActions.setError(message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSignOut() {
  while (true) {
    const { payload: path }: PayloadAction<string> = yield take(
      appActions.signOut
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );
    try {
      yield put(appActions.startLoading());
      yield signOut(auth);
      yield put(push({ ...location, pathname: path ?? '/login' }));
      yield put(appActions.setMaxStep(getStepsForPath('/login')));
      yield put(appActions.reset());
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
      yield put(appActions.setIsInitializing(false));
    }
  }
}

function* watchSaveUserData() {
  while (true) {
    const { payload }: PayloadAction<FormEditUser> = yield take(
      appActions.saveUserData
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );
    const { dbEnterprise, dbUser }: AppState = yield select(
      (state) => state.app
    );
    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: '/onboarding/edit-user',
      });
      const response: DBUser = yield ky(url, {
        method: 'POST',
        json: payload,
        headers,
        ...opts,
      }).json();
      yield put(appActions.setDBUser(response));
      const pathname = getCurrentPathnameForData(dbUser, dbEnterprise);
      yield put(push({ ...location, pathname }));
      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setDBEnterprise());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSaveEnterpriseData() {
  while (true) {
    const { payload }: PayloadAction<FormCompanySetup> = yield take(
      appActions.saveEnterpriseData
    );
    const { dbUser, dbEnterprise }: AppState = yield select(
      (state) => state.app
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );

    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: '/onboarding/company-setup',
      });
      const response: DBEnterprise = yield ky(url, {
        method: 'POST',
        json: {
          id: dbEnterprise?.id,
          pixRate: getPixRate(dbUser?.plan ?? 'lite'),
          ...payload,
        },
        headers,
        ...opts,
      }).json();
      yield put(appActions.setDBEnterprise(response));
      if (dbUser) {
        yield put(
          appActions.setDBUser({ ...dbUser, enterpriseId: response.id })
        );
      }
      if (!dbEnterprise) {
        console.log('GA 2_company_setup');
        logEvent(analytics, '2_company_setup');
      }

      const pathname = getCurrentPathnameForData(dbUser, response);
      yield put(push({ ...location, pathname }));
      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.setDBEnterprise());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSavePixData() {
  while (true) {
    const { payload }: PayloadAction<FormPixData> = yield take(
      appActions.savePixData
    );
    const { dbUser, dbEnterprise: prevDbEnterprise }: AppState = yield select(
      (state) => state.app
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );
    if (!dbUser || !dbUser.enterpriseId) throw new Error('Usuário não logado');

    try {
      yield put(appActions.startLoading());
      const { url, headers, ...opts } = yield getApi({
        path: `/onboarding/pix-setup`,
      });
      const dbEnterprise: DBEnterprise = yield ky(url, {
        method: 'POST',
        json: { id: dbUser.enterpriseId, ...payload },
        headers,
        ...opts,
      }).json();
      yield put(appActions.setDBEnterprise(dbEnterprise));
      if (!prevDbEnterprise?.pixKeyDestination) {
        console.log('GA 3_pix_setup');
        logEvent(analytics, '3_pix_setup');
      }
      yield put(appActions.finishLoading());
      const pathname = getCurrentPathnameForData(dbUser, dbEnterprise);
      yield put(push({ ...location, pathname }));
      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
    } catch (e) {
      yield put(appActions.savePixDataFail());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCheckPixKey() {
  while (true) {
    const { payload }: PayloadAction<CheckPix> = yield take(
      appActions.checkPixKey
    );
    const { dbUser }: AppState = yield select((state) => state.app);
    if (!dbUser || !dbUser.enterpriseId) throw new Error('Usuário não logado');

    try {
      yield put(appActions.startLoading());

      // const { url, headers, ...opts } = yield getApi({
      //   path: '/pix/check-pix-key',
      // });
      // const { error, result } = yield rpcCall<PixKeyBankData>({
      //   url,
      //   method: 'post',
      //   headers,
      //   payload: {
      //     pixAddressKey: payload.pixKeyDestination,
      //     pixAddressKeyType: payload.pixKeyDestinationType,
      //   },
      //   ...opts,
      // });
      // if (error) {
      //   throw new Error(error);
      // }
      yield put(
        appActions.setPixKeyBankData({
          bankName: 'x',
          ownerName: 'x',
          cpfCnpj: 'x',
        })
      );
      yield put(appActions.finishLoading());
    } catch (e) {
      yield put(appActions.savePixDataFail());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchSavePixAccountData() {
  while (true) {
    const {
      payload,
    }: PayloadAction<PixAccountData & { socialContractFile?: File }> =
      yield take(appActions.savePixAccountData);
    const { dbUser, dbEnterprise: prevDbEnterprise }: AppState = yield select(
      (state) => state.app
    );
    const { location }: ReduxRouterState = yield select(
      (state) => state.router
    );
    if (!dbUser || !dbUser.enterpriseId) throw new Error('Usuário não logado');

    try {
      yield put(appActions.startLoading());

      console.log('payload: ', payload);
      const socialContractId: string | undefined = payload.socialContractFile
        ? yield upload(payload.socialContractFile)
        : undefined;

      const { url, headers, ...opts } = yield getApi({
        path: `/onboarding/pix-account-setup`,
      });
      const dbEnterprise: DBEnterprise = yield ky(url, {
        method: 'POST',
        json: { id: dbUser.enterpriseId, ...payload, socialContractId },
        headers,
        ...opts,
      }).json();
      yield put(appActions.setDBEnterprise(dbEnterprise));

      if (!prevDbEnterprise?.pixAccountData) {
        console.log('GA 4_pix_account');
        logEvent(analytics, '4_pix_account');
      }

      // if (!prevDbEnterprise?.pixKeyDestination) {
      //   console.log('GA conversion');
      //   logEvent(analytics, 'conversion');
      //   console.log('GA purchase');
      //   logEvent(analytics, 'purchase');
      // }

      // if (
      //   !prevDbEnterprise?.pixAccountData &&
      //   dbEnterprise.pixAccountData &&
      //   auth.currentUser
      // )
      //   yield sendEmailVerification(auth.currentUser);

      yield put(appActions.finishLoading());
      const pathname = getCurrentPathnameForData(dbUser, dbEnterprise);
      yield put(push({ ...location, pathname }));
      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
    } catch (e) {
      yield put(appActions.savePixDataFail());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchCompleteFlow() {
  while (true) {
    yield take(appActions.completeFlow);
    const { dbUser, dbEnterprise: prevDbEnterprise }: AppState = yield select(
      (state) => state.app
    );
    if (!dbUser || !dbUser.enterpriseId) throw new Error('Usuário não logado');

    try {
      yield put(appActions.startLoading());

      const { url, headers, ...opts } = yield getApi({
        path: `/onboarding/complete-flow`,
      });
      const dbEnterprise: DBEnterprise = yield ky(url, {
        method: 'POST',
        json: { id: dbUser.enterpriseId },
        headers,
        ...opts,
      }).json();
      if (!prevDbEnterprise?.flowCompleted) {
        console.log('GA 5_completed_flow');
        logEvent(analytics, '5_completed_flow');
      }

      yield put(appActions.setDBEnterprise(dbEnterprise));
      yield put(appActions.finishLoading());
      const pathname = getCurrentPathnameForData(dbUser, dbEnterprise);
      yield put(push({ ...dbUser.location, pathname }));
      yield put(appActions.setMaxStep(getStepsForPath(pathname)));
    } catch (e) {
      yield put(appActions.savePixDataFail());
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchGetDownloadLink() {
  while (true) {
    const {
      payload: { id, buttonId },
    }: PayloadAction<{
      id: string;
      buttonId: string;
    }> = yield take(appActions.getDownloadLink);
    try {
      yield put(appActions.startLoadingButton(buttonId));

      const { url, headers, ...opts } = yield getApi({
        path: `/documents/${id}`,
      });
      const { url: downloadUrl }: GetDownloadLinkResponse = yield ky(url, {
        method: 'GET',
        headers,
        ...opts,
      }).json();

      if (window && window.open) {
        const newTab = window.open(downloadUrl, '_blank');
        if (newTab) newTab.focus();
      }
      yield put(appActions.stopLoadingButton(buttonId));
    } catch (e) {
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.stopLoadingButton(buttonId));
    }
  }
}

export default [
  watchErrors,
  watchSignUpWithEmail,
  watchSaveUserData,
  watchSaveEnterpriseData,
  watchSavePixData,
  watchSignUpWithAuthProvider,
  watchLoginWithEmailAndPassword,
  watchResumeLogin,
  watchLoginWithAuthProvider,
  watchCompleteLogin,
  watchSendPasswordResetEmail,
  watchConfirmPassword,
  watchSignOut,
  watchConfirmEmail,
  watchCheckPixKey,
  watchSavePixAccountData,
  watchGetDownloadLink,
  watchCompleteFlow,
];
