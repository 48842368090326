import { Theme, useMediaQuery } from '@mui/material';

const useIsSmallScreen = () => {
  const isSmallScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('md')
  );
  return isSmallScreen;
};

export default useIsSmallScreen;
