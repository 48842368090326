import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Button,
  CircularProgress,
  MobileStepper,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { ReactNode, useEffect } from 'react';
import { Route } from 'react-router';
import {
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Footer from '../../components/Footer';
import { Header } from '../../components/Header';
import ProtectedRoute from '../../components/ProtectedRoute';
import { auth } from '../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { getStepsForPath, steps } from '../../helpers/steps';
import useIsSmallScreen from '../../hooks/useIsSmallScreen';
import { AuthenticatedUser } from '../../types';
import Active from './Active';
import CompanySetup from './CompanySetup';
import EmailAction, { EmailActionType } from './EmailAction';
import Finish from './Finish';
import Login from './Login';
import PageError from './PageError';
import Pending from './Pending';
import PixAccountSetup from './PixAccountSetup';
import PixSetup from './PixSetup';
import SendEmailConfirm from './SendEmailConfirm';
import SendPasswordReset from './SendPasswordReset';
import SignUp from './SignUp';
import { appActions } from './app-slice';

type ContainerComponentProps = {
  children: ReactNode;
};
const ContainerComponent = ({ children }: ContainerComponentProps) => {
  const xs = useIsSmallScreen();
  return xs ? (
    <Box display="flex" flexDirection="column" minHeight="100dvh">
      {children}
    </Box>
  ) : (
    <Paper variant="elevation" sx={{ maxWidth: '500px', m: 2 }}>
      {children}
    </Paper>
  );
};

function App() {
  const {
    authenticatedUser,
    maxStep,
    isInitializing,
    dbEnterprise,
    isCurrentFormDirty,
  } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const mode = params.get('mode') as EmailActionType;

  useEffect(() => {
    dispatch(appActions.setIsInitializing(true));
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log('user: ', user);
        const authenticatedUser: AuthenticatedUser = {
          uid: user.uid,
          displayName: user.displayName,
          email: user.email,
          emailVerified: user.emailVerified,
          photoUrl: user.photoURL ?? undefined,
          provider: user.providerData.find(
            ({ providerId }) => providerId === 'google.com'
          )
            ? 'google'
            : 'email',
        };

        let willResumeLogin = true;

        if (
          ['/password-reset', '/send-email-confirm'].find((p) => p === pathname)
        )
          willResumeLogin = false;

        if (pathname === '/email-action' && mode === 'verifyEmail') {
          willResumeLogin = false;
        }

        if (willResumeLogin)
          dispatch(appActions.resumeLogin(authenticatedUser));
        else {
          dispatch(appActions.setAuthenticatedUser(authenticatedUser));
          dispatch(appActions.setIsInitializing(false));
        }
      } else {
        dispatch(appActions.setIsInitializing(false));
      }
    });
    return () => {
      unregisterAuthObserver();
    };
  }, [dispatch]);

  let [searchParams] = useSearchParams();
  const origin = searchParams.get('origin');
  useEffect(() => {
    if (origin) sessionStorage.setItem('br.com.zetaone.origin', origin);
  }, [origin]);

  const plan = searchParams.get('plan');
  useEffect(() => {
    if (plan) sessionStorage.setItem('br.com.zetaone.plan', plan);
  }, [plan]);

  const currentStep = getStepsForPath(pathname);

  const handleNext = () => {
    if (currentStep !== undefined) navigate(steps[currentStep + 1]);
  };
  const handleBack = () => {
    if (currentStep !== undefined) navigate(steps[currentStep - 1]);
  };

  return (
    <>
      <Stack
        direction="column"
        sx={{
          minHeight: '100dvh',
          background: {
            md: 'linear-gradient(130deg, rgba(171,143,194,1) 0%, rgba(100,70,122,1) 100%)',
          },
        }}
      >
        <Box
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: 'none', md: 'center' }}
          alignItems="center"
        >
          <Box>
            <ContainerComponent>
              {isInitializing ? (
                <>
                  <Stack
                    direction="column"
                    gap={2}
                    alignItems="center"
                    sx={{ minHeight: '200px', minWidth: '200px' }}
                    justifyContent="center"
                    p={4}
                  >
                    <Typography variant="h5" textAlign="center">
                      Iniciando aplicação
                    </Typography>
                    <CircularProgress size={100} />
                  </Stack>
                </>
              ) : (
                <>
                  <Header />
                  <Stack
                    direction="column"
                    justifyContent="center"
                    paddingX={4}
                    gap={2}
                  >
                    <Routes>
                      <Route path="/" element={<SignUp />} />
                      <Route path="/login" element={<Login />} />
                      <Route
                        path="/password-reset"
                        element={<SendPasswordReset />}
                      />
                      <Route path="/email-action" element={<EmailAction />} />
                      <Route
                        path="/send-email-confirm"
                        element={<SendEmailConfirm />}
                      />
                      <Route
                        path="/company-setup"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <CompanySetup />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/pix-setup"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <PixSetup />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/account-setup"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <PixAccountSetup />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/finish"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <Finish />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/pending"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <Pending />
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path="/active"
                        element={
                          <ProtectedRoute allow={!!authenticatedUser}>
                            <Active />
                          </ProtectedRoute>
                        }
                      />
                      <Route path="*" element={<PageError />} />
                    </Routes>
                  </Stack>
                  {currentStep !== undefined &&
                  !dbEnterprise?.flowCompleted &&
                  pathname !== '/send-email-confirm' &&
                  pathname !== '/email-action' &&
                  pathname !== '/password-reset' ? (
                    <>
                      <Box flexGrow={1} />
                      <MobileStepper
                        sx={{
                          background: 'none',
                          boxShadow: 'none',
                          p: 2,
                        }}
                        variant="dots"
                        steps={5}
                        position="static"
                        activeStep={currentStep}
                        nextButton={
                          <Button
                            size="small"
                            onClick={handleNext}
                            disabled={
                              currentStep >= maxStep || isCurrentFormDirty
                            }
                          >
                            Próximo
                            <KeyboardArrowRight />
                          </Button>
                        }
                        backButton={
                          <Button
                            size="small"
                            onClick={handleBack}
                            disabled={currentStep === 0 || isCurrentFormDirty}
                          >
                            <KeyboardArrowLeft />
                            Anterior
                          </Button>
                        }
                      />
                    </>
                  ) : (
                    <Box p={2}></Box>
                  )}
                </>
              )}
            </ContainerComponent>
          </Box>
        </Box>
      </Stack>
      <Footer />
    </>
  );
}

export default App;
