import { LoadingButton } from '@mui/lab';
import React from 'react';
import { useAppSelector } from '../../helpers/hooks';

type SignUpButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const SignUpButton = ({ onClick }: SignUpButtonProps) => {
  const { isLoading } = useAppSelector((state) => state.app);
  return (
    <>
      <LoadingButton
        loading={isLoading}
        onClick={onClick}
        variant="contained"
        type="submit"
      >
        Registrar
      </LoadingButton>
    </>
  );
};

export default SignUpButton;
