import { LoadingButton } from '@mui/lab';
import { Alert, Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { appActions } from './app-slice';

function Finish() {
  const { isLoading } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  return (
    <Stack direction="column" justifyContent="center" gap={2}>
      <Typography variant="h5" textAlign="center">
        Confirmação!
      </Typography>

      <Typography variant="body1" textAlign="center">
        Se quiser revisar alguma informação, agora é a hora.
      </Typography>
      <Typography variant="body1" textAlign="center">
        Você pode voltar aos passos anteriores clicando nos botões de navegação
        abaixo.
      </Typography>
      <Alert
        severity="warning"
        variant="outlined"
        sx={{ boxShadow: 'none', borderRadius: '8px' }}
      >
        Ao clicar em CONCLUIR, não será mais possível alterar os dados.
      </Alert>
      <LoadingButton
        loading={isLoading}
        onClick={() => dispatch(appActions.completeFlow())}
        variant="contained"
      >
        Concluir
      </LoadingButton>
    </Stack>
  );
}

export default Finish;
