import { LoadingButton } from '@mui/lab';
import {
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PhoneConsentText } from '../../components/PhoneConsent';
import { PhoneInput } from '../../components/PhoneInput';
import ProviderIcon from '../../components/ProviderIcon';
import { formatValue } from '../../helpers/formatter';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { FormEditUser } from '../../types';
import { appActions } from './app-slice';

const SignUpEditForm = () => {
  const { error, dbUser, isLoading } = useAppSelector((state) => state.app);
  const defaultValues: FormEditUser = {
    id: '123',
    phoneNumber: dbUser?.phoneNumber
      ? formatValue(dbUser?.phoneNumber, 'phone')
      : '',
  };
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    reset,
  } = useForm<FormEditUser>({
    defaultValues,
  });

  useEffect(() => {
    dispatch(appActions.setIsCurrentFormDirty(isDirty));
  }, [isDirty]);

  const dispatch = useAppDispatch();
  const onSubmit = (data: FormEditUser) => {
    if (!dbUser?.id) throw new Error('Erro salvando dados');
    dispatch(
      appActions.saveUserData({
        phoneNumber: data.phoneNumber.replace(/[^\d+]+/g, ''),
        id: dbUser.id,
      })
    );
    dispatch(appActions.setIsCurrentFormDirty(false));
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={2}>
        <Typography variant="body1">
          Você pode alterar alguns dados se quiser:
        </Typography>
        {error !== '' && (
          <Typography variant="body1" color="error">
            {error}
          </Typography>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome"
              type="text"
              value={dbUser?.displayName}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProviderIcon provider={dbUser?.signUpMethod} />
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="E-mail"
              type="text"
              value={dbUser?.email}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ProviderIcon provider={dbUser?.signUpMethod} />
                  </InputAdornment>
                ),
              }}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneInput
              type="text"
              placeholder="(00) 00000-0000"
              fullWidth
              label="Telefone Celular"
              name="phoneNumber"
              control={control}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <PhoneConsentText />
          </Grid>
        </Grid>
        <Stack direction="row" gap={2} width={1}>
          {isDirty && (
            <Stack flexBasis="100%">
              <LoadingButton
                loading={isLoading}
                onClick={() => reset()}
                variant="outlined"
                color="error"
                disabled={!isDirty}
              >
                Descartar
              </LoadingButton>
            </Stack>
          )}
          <Stack flexBasis="100%">
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disabled={!isDirty}
              type="submit"
            >
              Salvar
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
};

export default SignUpEditForm;
