import { TextField, TextFieldProps, TextFieldVariants } from '@mui/material';
import React from 'react';
import { Control, Validate, useController } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { testCPF } from '../helpers/validations';

type CustomProps = {
  // eslint-disable-next-line no-unused-vars
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        inputRef={ref}
        onChange={() => {}}
        onAccept={(value: any) =>
          onChange({ target: { name: props.name, value } })
        }
      />
    );
  }
);

type CPFInputProps = { variant?: TextFieldVariants } & Omit<
  TextFieldProps,
  'variant'
> & {
    control: Control<any, any>;
    customValidate?: Validate<any, any>;
  };

export const CPFInput = (props: CPFInputProps) => {
  const {
    control,
    customValidate,
    InputProps,
    onChange: onTextFieldChange,
    ...other
  } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: other.name ?? '',
    control,
    rules: {
      validate:
        customValidate ??
        ((value) => {
          return testCPF(value) || 'CPF inválido';
        }),
    },
    defaultValue: '',
  });
  return (
    <TextField
      {...other}
      error={!!error}
      helperText={error?.message}
      value={value}
      onChange={(e) => {
        if (onTextFieldChange) onTextFieldChange(e);
        onChange(e.target.value.replace(/[^\d+]+/g, ''));
      }}
      InputProps={{ ...InputProps, inputComponent: TextMaskCustom as any }}
    />
  );
};
