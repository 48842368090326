import { Box, Link, useTheme } from '@mui/material';

function Footer() {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        [theme.breakpoints.down('md')]: {
          display: 'none',
        },
      }}
      p={1}
    >
      <Link color="inherit" href="https://www.zetaone.com.br/">
        <img width="32" height="32" src="images/simbolo.svg" />
      </Link>{' '}
    </Box>
  );
}

export default Footer;
