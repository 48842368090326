import { Box, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import React from 'react';
import { Control, useController } from 'react-hook-form';

type ButtonGroupInputOption = {
  label: string;
  value: any;
};
type ButtonGroupInputProps = {
  name: string;
  control: Control<any, any>;
  options: ButtonGroupInputOption[];
  orientation?: 'horizontal' | 'vertical';
};

export const ButtonGroupInput = (props: ButtonGroupInputProps) => {
  const { control, name, options, orientation } = props;
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name: name ?? '',
    control,
    rules: {
      required: 'Campo obrigatório',
    },
  });
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newValue: any | null
  ) => {
    // if (newValue !== null) {
    onChange(newValue);
    // }
  };
  return (
    <>
      <ToggleButtonGroup
        value={value}
        color="primary"
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
        orientation={orientation}
        fullWidth
        sx={{
          ...(error
            ? {
                ['& .MuiButtonBase-root']: {
                  borderColor: 'error.main',
                  color: 'error.main',
                },
              }
            : {}),
          ['& .MuiButtonBase-root.Mui-selected']: {
            backgroundColor: 'primary.main',
            color: 'white',
          },
          ['& .MuiButtonBase-root.Mui-selected:hover']: {
            backgroundColor: 'primary.light',
            color: 'white',
          },
          ['& .MuiToggleButton-root:hover']: {
            backgroundColor: 'primary.light',
            color: 'white',
          },
        }}
      >
        {options.map(({ label, value }, i) => (
          <ToggleButton key={i} value={value} aria-label={value}>
            {label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      {error && (
        <Box paddingLeft={2}>
          <Typography variant="caption" color="error">
            {error.message}
          </Typography>
        </Box>
      )}
    </>
  );
};
