import dayjs from 'dayjs';
import { testCNPJ } from './validations';

export type FormatType =
  | 'text'
  | 'decimal'
  | 'money'
  | 'integer'
  | 'boolean'
  | 'docNumber'
  | 'phone'
  | 'futureDate'
  | 'date'
  | 'yearMonth'
  | 'month'
  | 'time'
  | 'dateTime';

export const formatValue = (value: any, type: FormatType) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (value === undefined) return '';
  switch (type) {
    case 'text':
      return value;
    case 'decimal':
      return `${formatter.format(value)}`;
    case 'money':
      return `R$ ${formatter.format(value)}`;
    case 'integer':
      return `${value}`;
    case 'boolean':
      return value ? 'Sim' : 'Não';
    case 'docNumber':
      if (!value) return '';
      if (testCNPJ(value))
        return `${value.substring(0, 2)}.${value.substring(
          2,
          5
        )}.${value.substring(5, 8)}/${value.substring(8, 12)}-${value.substring(
          12
        )}`;
      else
        return `${value.substring(0, 3)}.${value.substring(
          3,
          6
        )}.${value.substring(6, 9)}-${value.substring(9)}`;
    case 'phone': {
      if (value[0] === '+') return value;
      let nationalPhone = value;
      if (value.slice(0, 2) === '55') nationalPhone = value.slice(2);
      const ix = nationalPhone.length - 4;
      return `(${nationalPhone.substring(0, 2)}) ${nationalPhone.substring(
        2,
        ix
      )}-${nationalPhone.substring(ix)}`;
    }
    case 'futureDate':
    case 'date':
      if (!value) return '';
      return dayjs(value).format('DD/MM/YYYY');
    case 'yearMonth':
      if (!value) return '';
      return dayjs(value).format('MM/YYYY');
    case 'month':
      if (!value) return '';
      return dayjs(value).format('MMM');
    case 'time':
      if (!value) return '';
      return dayjs(value).format('HH:mm:ss');
    case 'dateTime':
      if (!value) return '';
      return `${dayjs(value).format('DD/MM/YYYY')}\u00A0às\u00A0${dayjs(
        value
      ).format('HH:mm:ss')}`;
  }
  return '';
};
