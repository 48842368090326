import LogoutIcon from '@mui/icons-material/Logout';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { appActions } from '../features/app/app-slice';
import { useAppDispatch, useAppSelector } from '../helpers/hooks';

type HeaderProps = {
  small?: boolean;
};

export const Header = ({ small }: HeaderProps) => {
  const dispatch = useAppDispatch();

  const { dbUser } = useAppSelector((state) => state.app);
  const handleAvatarClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const signOutClick = () => {
    dispatch(appActions.signOut());
  };
  if (dbUser)
    return (
      <>
        <Box
          p={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          sx={{ '& img#logo': { paddingLeft: 1, maxWidth: '130px' } }}
        >
          <img id="logo" src="images/logo.svg" alt="Logo" />
          <Box flexGrow={1} />
          <IconButton
            aria-label="profile"
            color="inherit"
            onClick={handleAvatarClick}
            size="small"
          >
            <Avatar
              sx={{ bgcolor: 'secondary.main' }}
              src={dbUser.photoUrl}
              alt={dbUser.displayName ?? dbUser.email ?? ''}
            >
              {(dbUser.displayName ??
                dbUser.email ??
                '')[0].toLocaleUpperCase()}
            </Avatar>
          </IconButton>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              borderRadius: 0.25,
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 20,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem disabled>{dbUser.displayName}</MenuItem>
          <Divider />
          <MenuItem onClick={signOutClick}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            Sair
          </MenuItem>
        </Menu>
      </>
    );
  else
    return (
      <Box
        p={4}
        display="flex"
        justifyContent="center"
        sx={{ '& img': { maxWidth: small ? '100px' : '200px' } }}
      >
        <img width="200" src="images/logo.svg" alt="Logo" />
      </Box>
    );
};
