import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { ButtonGroupInput } from '../../components/ButtonGroupInput';
import { CEPInput } from '../../components/CEPInput';
import { CNPJInput } from '../../components/CNPJInput';
import { CPFInput } from '../../components/CPFInput';
import { DateFieldWithControl } from '../../components/DateField/DateFieldWithControl';
import DocumentDetail from '../../components/DocumentDetail';
import { PhoneInput } from '../../components/PhoneInput';
import { MaskedInputWithRef } from '../../components/TextField/CurrencyMaskedInput';
import { TextFieldWithControl } from '../../components/TextField/TextFieldWithControl';
import { priceMask } from '../../helpers/create-number-mask';
import { formatValue } from '../../helpers/formatter';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import {
  parseMaskedInput,
  safeParseMaskedInput,
} from '../../helpers/safe-parse';
import { MimeType } from '../../helpers/validations';
import { PixAccountData, PixAccountForm } from '../../types';
import { appActions } from './app-slice';

export const accountTypes = [
  {
    value: 'PF',
    label: 'Pessoa Física',
  },
  {
    value: 'PJ',
    label: 'Pessoa Jurídica',
  },
];

export const companyTypes = [
  {
    value: 'MEI',
    label: 'Micro Empreendedor Individual',
  },
  {
    value: 'LIMITED',
    label: 'Empresa Limitada',
  },
  {
    value: 'INDIVIDUAL',
    label: 'Empresa Individual',
  },
  {
    value: 'ASSOCIATION',
    label: 'Associação',
  },
];

function PixAccountSetup() {
  const {
    isLoading /*, pixKeyBankData*/,
    dbEnterprise,
    completedFlow,
    dbUser,
    loadingButtons,
  } = useAppSelector((state) => state.app);

  const [isEditingSocialContract, setIsEditingSocialContract] = useState(false);

  const defaultValues: PixAccountForm = {
    accountType: dbEnterprise?.pixAccountData?.accountType ?? 'PJ',
    birthDate: dbEnterprise?.pixAccountData?.birthDate ?? '',
    companyType: dbEnterprise?.pixAccountData?.companyType ?? 'LIMITED',
    cpf: dbEnterprise?.pixAccountData?.cpf ?? '',
    cnpj: dbEnterprise?.pixAccountData?.cnpj ?? '',
    mobilePhone: dbEnterprise?.pixAccountData?.mobilePhone
      ? formatValue(dbEnterprise?.pixAccountData?.mobilePhone, 'phone')
      : '',
    incomeValue: dbEnterprise?.pixAccountData?.incomeValue.toString() ?? '',
    address: dbEnterprise?.pixAccountData?.address ?? '',
    addressNumber: dbEnterprise?.pixAccountData?.addressNumber ?? '',
    complement: dbEnterprise?.pixAccountData?.complement ?? '',
    province: dbEnterprise?.pixAccountData?.province ?? '',
    postalCode: dbEnterprise?.pixAccountData?.postalCode ?? '',
    socialContractId: dbEnterprise?.pixAccountData?.socialContractId ?? '',
    socialContractFile: undefined,
  };

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
    resetField,
    reset,
  } = useForm<PixAccountForm>({ defaultValues });

  const socialContractId = watch('socialContractId');
  const watchAccountType = watch('accountType');

  const onSubmit: SubmitHandler<PixAccountForm> = (data) => {
    const dataToSave: PixAccountData = {
      ...data,
      mobilePhone: data.mobilePhone.replace(/[^\d+]+/g, ''),
      incomeValue: safeParseMaskedInput(data.incomeValue),
    };
    dispatch(
      appActions.savePixAccountData({
        ...dataToSave,
        socialContractFile: data.socialContractFile,
      })
    );
    dispatch(appActions.setIsCurrentFormDirty(false));
  };

  const getDocumentLink = (id?: string, buttonId?: string) => {
    if (id && buttonId) dispatch(appActions.getDownloadLink({ id, buttonId }));
  };

  useEffect(() => {
    dispatch(appActions.setIsCurrentFormDirty(isDirty));
  }, [isDirty]);

  const maxFileMB = 8;
  const acceptedFileTypes: MimeType[] = [
    'application/pdf',
    'application/zip',
    'image/jpeg',
    'image/png',
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={2}>
        <Typography variant="body1">
          Agora vamos configurar a sua conta digital.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert
              severity="info"
              variant="outlined"
              sx={{ boxShadow: 'none', borderRadius: '8px' }}
            >
              Nesta etapa criaremos uma conta bancária digital que será usada
              para receber o pagamento dos seus clientes.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Primeiro escolha o tipo de conta:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Alert
              severity="warning"
              variant="outlined"
              sx={{ boxShadow: 'none', borderRadius: '8px' }}
            >
              Atenção! Não será possível alterar o tipo de conta no futuro.
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroupInput
              name="accountType"
              control={control}
              options={accountTypes}
            />
          </Grid>
          {watchAccountType === 'PJ' && (
            <Grid item xs={12}>
              <CNPJInput
                type="text"
                placeholder="00.000.000/0000-00"
                fullWidth
                label="CNPJ"
                name="cnpj"
                control={control}
                error={!!errors.cnpj}
                helperText={errors.cnpj?.message}
              />
            </Grid>
          )}
          {watchAccountType === 'PF' && (
            <Grid item xs={12}>
              <CPFInput
                type="text"
                placeholder="000.000.000-00"
                fullWidth
                label="CPF"
                name="cpf"
                control={control}
                error={!!errors.cpf}
                helperText={errors.cpf?.message}
              />
            </Grid>
          )}
          {watchAccountType === 'PF' && (
            <Grid item xs={6}>
              <DateFieldWithControl
                label="Data de Nascimento"
                name="birthDate"
                control={control}
                rules={{ required: 'O campo é obrigatório' }}
              />
            </Grid>
          )}

          <Grid item xs={6}>
            <PhoneInput
              type="text"
              placeholder="(00) 00000-0000"
              fullWidth
              label="Telefone Celular"
              name="mobilePhone"
              control={control}
              error={!!errors.mobilePhone}
              helperText={errors.mobilePhone?.message}
            />
          </Grid>
          {watchAccountType === 'PF' && (
            <>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Endereço"
                  type="text"
                  placeholder="Rua das Acácias"
                  {...register('address', {
                    required: 'O nome é obrigatório',
                    minLength: {
                      value: 2,
                      message: 'O nome é muito curto',
                    },
                  })}
                  error={!!errors.address}
                  helperText={errors.address?.message}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Número"
                  type="text"
                  placeholder="123"
                  {...register('addressNumber', {
                    required: 'O número é obrigatório',
                  })}
                  error={!!errors.addressNumber}
                  helperText={errors.addressNumber?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Complemento"
                  type="text"
                  placeholder="Apto 45"
                  {...register('complement', {
                    required: false,
                  })}
                  error={!!errors.complement}
                  helperText={errors.complement?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Bairro"
                  type="text"
                  placeholder="Jardim da Lapa"
                  {...register('province', {
                    required: 'O bairro é obrigatório',
                    minLength: {
                      value: 2,
                      message: 'O bairro é muito curto',
                    },
                  })}
                  error={!!errors.province}
                  helperText={errors.province?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <CEPInput
                  type="text"
                  placeholder="00000-000"
                  fullWidth
                  label="CEP"
                  name="postalCode"
                  control={control}
                  error={!!errors.postalCode}
                  helperText={errors.postalCode?.message}
                />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <TextFieldWithControl
              id="incomeValue"
              type="text"
              name="incomeValue"
              control={control}
              fullWidth
              label={
                watchAccountType === 'PJ'
                  ? 'Faturamento mensal'
                  : 'Renda mensal'
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">/mês</InputAdornment>
                ),
                inputComponent: MaskedInputWithRef as any,
              }}
              inputProps={{
                mask: priceMask,
              }}
              rules={{
                validate: (v) => {
                  if (v === undefined || v === '')
                    return 'O campo é obrigatório';
                  const val = parseMaskedInput(v);
                  if (isNaN(val)) return 'Valor inválido';
                  return true;
                },
              }}
            />
          </Grid>
          {watchAccountType === 'PJ' && (
            <>
              <Grid item xs={12}>
                <Alert
                  severity="info"
                  variant="outlined"
                  sx={{
                    boxShadow: 'none',
                    borderRadius: '8px',
                  }}
                >
                  Para abertura da conta, é necessário enviar o contrato social
                  atualizado, contendo todas as páginas e com o carimbo. <br />
                  Caso NÃO esteja com o documento em formato digital nesse
                  momento, ele poderá ser enviado mais tarde.
                </Alert>
              </Grid>
              <Grid item xs={12}>
                <DocumentDetail
                  label="Contrato Social"
                  mode={
                    socialContractId === '' || isEditingSocialContract
                      ? 'edit'
                      : 'display'
                  }
                  name="socialContractFile"
                  missing={false}
                  isDownloading={loadingButtons['socialContract']}
                  onEditClick={() => setIsEditingSocialContract(true)}
                  onClearClick={() => {
                    setIsEditingSocialContract(false);
                    resetField('socialContractFile');
                    resetField('socialContractId');
                  }}
                  onDownloadClick={() =>
                    getDocumentLink(
                      dbEnterprise?.pixAccountData?.socialContractId,
                      'socialContract'
                    )
                  }
                  canEdit
                  canDelete={false}
                  control={control}
                />
              </Grid>
            </>
          )}
        </Grid>
        <Stack direction="row" gap={2} width={1}>
          {isDirty && dbEnterprise?.pixAccountData && (
            <Stack flexBasis="100%">
              <LoadingButton
                loading={isLoading}
                onClick={() => reset()}
                variant="outlined"
                color="error"
                disabled={!isDirty}
              >
                Descartar
              </LoadingButton>
            </Stack>
          )}
          <Stack flexBasis="100%">
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disabled={!isDirty}
              type="submit"
            >
              {dbEnterprise?.pixAccountData ? 'Salvar' : 'Próximo'}
            </LoadingButton>
          </Stack>
        </Stack>
        {Object.keys(errors).length > 0 ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <ErrorIcon color="error" />
            <Box paddingLeft={1}>
              <Typography variant="caption" textAlign="center" color="error">
                Por favor corrija os erros antes de prosseguir
              </Typography>
            </Box>
          </Stack>
        ) : (
          <></>
        )}
      </Stack>
    </form>
  );
}

export default PixAccountSetup;
