import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Chip,
  LinearProgress,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { PasswordStrength } from '../helpers/validations';

type PasswordStrengthIndicatorProps = {
  passwordStrength: PasswordStrength;
};
export const PasswordStrengthIndicator = ({
  passwordStrength,
}: PasswordStrengthIndicatorProps) => {
  const theme = useTheme();
  let color:
    | 'error'
    | 'warning'
    | 'success'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | undefined;
  let txtStrength;
  let value: number;

  const { strength, conditions } = passwordStrength;
  switch (strength) {
    case 'weak':
      color = 'error';
      txtStrength = 'Fraca';
      value = 33;
      break;
    case 'medium':
      color = 'warning';
      txtStrength = 'Média';
      value = 66;
      break;
    case 'strong':
      color = 'success';
      txtStrength = 'Ótima';
      value = 100;
      break;
    default:
      color = 'primary';
      txtStrength = '';
      value = 0;
      break;
  }
  if (strength === 'empty') return <></>;
  return (
    <Box sx={{ width: '100%' }}>
      <Stack direction="column" p={1}>
        <Stack direction="row" marginBottom={1}>
          <Typography variant="caption">Qualidade da senha</Typography>
          <Box flexGrow={1} />
          <Chip size="small" color={color} label={txtStrength} />
        </Stack>
        <LinearProgress
          sx={{ height: '8px', borderRadius: '4px' }}
          variant="determinate"
          value={value}
          color={color}
        />
        {strength !== 'strong' && (
          <Box marginTop={1}>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.error.main }}
            >
              A senha precisa conter:
            </Typography>
            {conditions.map((c, i) => (
              <Stack direction="row" key={i} spacing={1}>
                {c.valid ? (
                  <CheckIcon fontSize="small" color="success" />
                ) : (
                  <ErrorIcon fontSize="small" color="error" />
                )}
                <Typography
                  sx={{
                    color: c.valid
                      ? theme.palette.success.main
                      : theme.palette.error.main,
                  }}
                  variant="body2"
                >
                  {c.label}
                </Typography>
              </Stack>
            ))}
          </Box>
        )}
      </Stack>
    </Box>
  );
};
