import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import { Box, Button } from '@mui/material';
import GoogleLogo from '../../components/GoogleLogo';
import { SignupMethod } from '../../types';

type SignUpMethodsProps = {
  onMethodSelect: (method: SignupMethod) => void;
};
function SignUpMethods({ onMethodSelect }: SignUpMethodsProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      marginX="auto"
      maxWidth="400px"
    >
      <Button
        variant="outlined"
        onClick={() => onMethodSelect('google')}
        sx={{
          padding: '12px',
          height: '40px',
          color: 'rgba(0,0,0,0.54)',
          fontFamily: 'roboto',
          fontSize: '14px',
          fontWeight: 500,
          borderColor: '#dadce0',
          // boxShadow:
          //   '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
          [':hover']: {
            borderColor: '#d2e3fc',
            backgroundColor: 'rgba(66,133,244,.04)',
          },
        }}
      >
        <GoogleLogo />
        <Box marginLeft="12px" flexGrow={1}>
          Inscrever-se com o Google
        </Box>
      </Button>
      <Button
        variant="contained"
        onClick={() => onMethodSelect('email')}
        sx={{
          padding: '12px',
          height: '40px',
          // color: 'rgba(0,0,0,0.54)',
          // fontFamily: 'roboto',
          // fontSize: '14px',
          // fontWeight: 500,
          // borderColor: '#dadce0',
          // boxShadow:
          //   '0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12)',
          // [':hover']: {
          //   border: 'none',
          // },
        }}
      >
        <AlternateEmailIcon />
        <Box marginLeft="12px" flexGrow={1}>
          Inscrever-se com e-mail/senha
        </Box>
      </Button>
    </Box>
  );
}

export default SignUpMethods;
