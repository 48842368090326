import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

type ProtectedRouteProps = {
  allow: boolean;
  children: ReactNode;
};

function ProtectedRoute({ allow, children }: ProtectedRouteProps) {
  return allow ? <>{children}</> : <Navigate to="/login" replace />;
}
export default ProtectedRoute;
