import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';

declare var process: any;

export type AuthErrorCode =
  | 'auth/email-already-in-use'
  | 'auth/invalid-email'
  | 'auth/operation-not-allowed'
  | 'auth/weak-password'
  | 'auth/popup-closed-by-user'
  | 'auth/wrong-password'
  | 'auth/user-not-found'
  | 'auth/too-many-requests'
  | 'auth/invalid-action-code';

// const googleIdMap: Record<string, string> = {
//   'condomi-prod': '',
//   'condomi-dev': '',
//   'condomi-dev2':
//     '977017560447-r2o5rg0u63966h2i5dmni6ak98jt1bfl.apps.googleusercontent.com',
//   'condomi-stg': '',
// };

// export const getGoogleId = () =>
//   googleIdMap[process.env.REACT_APP_ENV as string];

const config: Record<string, any> = {
  'condomi-prod': {
    apiKey: 'AIzaSyD6Qa0gRoBHUJtfV7t19rhjzGCloY6Gt50',
    authDomain: 'condomi-prod.firebaseapp.com',
    projectId: 'condomi-prod',
    storageBucket: 'condomi-prod.appspot.com',
    messagingSenderId: '866455857563',
    appId: '1:866455857563:web:5073cb39d7ed872e68d93e',
    measurementId: 'G-WV0FBCCEBV',
  },
  // 'condomi-dev': {
  //   apiKey: 'AIzaSyDZ388wOJJGuQ1o30UvcpITryjW29uDCxk',
  //   authDomain: 'condomi-dev.firebaseapp.com',
  //   projectId: 'condomi-dev',
  //   storageBucket: 'condomi-dev.appspot.com',
  //   messagingSenderId: '782340485308',
  //   appId: '1:782340485308:web:0c828bc2ea00477129e0b9',
  //   measurementId: 'G-86941FH24V',
  // },
  'condomi-dev2': {
    apiKey: 'AIzaSyB62P8JUBfhDdSTtzMrbR4EdKe-YNfQ5SI',
    authDomain: 'condomi-dev2.firebaseapp.com',
    projectId: 'condomi-dev2',
    storageBucket: 'condomi-dev2.appspot.com',
    messagingSenderId: '977017560447',
    appId: '1:977017560447:web:4759dc1c4f0e2c01748e4a',
    measurementId: 'G-ECY7J4YZ14',
  },
  // 'condomi-stg': {
  //   apiKey: 'AIzaSyBvDoDfLboUNupHhPIq1tkgqvooMWltsmM',
  //   authDomain: 'condomi-stg.firebaseapp.com',
  //   projectId: 'condomi-stg',
  //   storageBucket: 'condomi-stg.appspot.com',
  //   messagingSenderId: '1038698077057',
  //   appId: '1:1038698077057:web:771e9809be44c1a265a680',
  // },
};

export const app = initializeApp(config[process.env.REACT_APP_ENV as string]);
export const db = initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});
export const auth = getAuth(app);
export const analytics = getAnalytics(app);

logEvent(analytics, 'page_view');
