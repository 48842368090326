import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './features/app/App';
import Notifier from './features/notifier/Notifier';
import { lightTheme } from './helpers/themes';
import reportWebVitals from './reportWebVitals';
import store, { history } from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <Provider store={store}>
        <ReduxRouter history={history}>
          <SnackbarProvider maxSnack={3}>
            <Notifier />
            <App />
          </SnackbarProvider>
        </ReduxRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
