import { Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { PhoneConsentText } from '../../components/PhoneConsent';
import { PhoneInput } from '../../components/PhoneInput';
import ProviderIcon from '../../components/ProviderIcon';
import { useAppSelector } from '../../helpers/hooks';
import { FormSignUp } from '../../types';
import SignUpButton from './SignUpButton';

type SignUpProviderFormProps = {
  onSubmit: (data: FormSignUp) => void;
};
const SignUpProviderForm = ({ onSubmit }: SignUpProviderFormProps) => {
  const { error, authenticatedUser, authProvider } = useAppSelector(
    (state) => state.app
  );
  const { handleSubmit, control } = useForm<FormSignUp>();
  const onSubmitClick = (data: FormSignUp) => {
    onSubmit(data);
  };

  return (
    <>
      {error !== '' && (
        <Typography variant="body1" textAlign="center" color="error">
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome"
            type="text"
            value={authenticatedUser?.displayName}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ProviderIcon provider={authProvider} />
                </InputAdornment>
              ),
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            type="text"
            value={authenticatedUser?.email}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ProviderIcon provider={authProvider} />
                </InputAdornment>
              ),
            }}
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            placeholder="(00) 00000-0000"
            fullWidth
            label="Telefone Celular"
            name="phoneNumber"
            control={control}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <PhoneConsentText />
      </Grid>
      <SignUpButton onClick={handleSubmit(onSubmitClick)} />
    </>
  );
};

export default SignUpProviderForm;
