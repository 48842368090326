import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { Control, Validate } from 'react-hook-form';
import FileUploadWithControl from './FileUpload/FileUploadWithControl';

type DocumentDetailProps = {
  label: string;
  mode: 'edit' | 'display';
  name: string;
  missing: boolean;
  isDownloading: boolean;
  onEditClick: React.MouseEventHandler<HTMLButtonElement>;
  onClearClick: React.MouseEventHandler<HTMLButtonElement>;
  onDownloadClick: React.MouseEventHandler<HTMLButtonElement>;
  canEdit: boolean;
  canDelete: boolean;
  control: Control<any, any>;
  customValidate?: Validate<any, any>;
  children?: React.ReactNode;
};

export default function DocumentDetail({
  label,
  mode,
  name,
  missing,
  isDownloading,
  onEditClick,
  onClearClick,
  onDownloadClick,
  canEdit,
  canDelete,
  control,
  customValidate,
  children,
}: DocumentDetailProps) {
  return (
    <>
      <Box
        display={mode === 'display' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        <CheckIcon color="success" />
        <LoadingButton
          loading={isDownloading}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          onClick={onDownloadClick}
        >
          {label}
        </LoadingButton>
        <Box flexGrow={1}></Box>
        {children}
        {canEdit && (
          <Tooltip title="Substituir documento">
            <IconButton onClick={onEditClick}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Box
        display={mode === 'edit' ? 'flex' : 'none'}
        flexDirection="row"
        alignItems="center"
        gap={2}
      >
        {missing ? <ErrorIcon color="error" /> : <CheckIcon color="success" />}
        <FileUploadWithControl
          fullWidth
          disabled={!canEdit}
          label={label}
          name={name}
          control={control}
          rules={{
            validate: customValidate,
          }}
        />
        {canDelete && (
          <IconButton onClick={onClearClick}>
            <DeleteIcon />
          </IconButton>
        )}
      </Box>
    </>
  );
}
