import { Button, Stack, Typography } from '@mui/material';

function PageError() {
  return (
    <>
      <Stack direction="column" justifyContent="center" p={4} gap={4}>
        <Typography variant="h5" textAlign="center">
          Página não encontrada
        </Typography>

        <Button href="/" variant="contained">
          Voltar
        </Button>
      </Stack>
    </>
  );
}

export default PageError;
