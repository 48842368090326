import { Typography } from '@mui/material';

export const PhoneConsentText = () => (
  <>
    <Typography variant="caption" component="p">
      Ao fornecer este número, você concorda em receber comunicações da ZetaOne
      referentes ao serviço contratado.
    </Typography>
    <Typography variant="caption" component="p">
      A ZetaOne e os seus parceiros não enviarão mensagens de marketing.
    </Typography>
  </>
);
