import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { v4 as uuid } from 'uuid';

export const upload = async (file: File): Promise<string> => {
  const id = uuid();
  const fileRef = ref(getStorage(), id);
  await uploadBytes(fileRef, file, {
    customMetadata: {
      filename: file.name,
      filetype: file.type,
    },
  });
  return id;
};
