import { Alert, Box, Stack, Typography } from '@mui/material';

function SendEmailConfirm() {
  return (
    <Stack direction="column" gap={2}>
      <Typography variant="body1" textAlign="center">
        Confirmação do e-mail
      </Typography>
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        sx={{ '& img': { maxWidth: '150px' } }}
      >
        <img src="images/check.svg" />
      </Box>
      <Typography variant="body1" textAlign="center">
        Antes de completar o registro, é necessário confirmar seu e-mail.
      </Typography>
      <Typography variant="body1" textAlign="center">
        Verifique sua caixa de entrada e siga as instruções.
      </Typography>
      <Alert
        severity="info"
        variant="outlined"
        sx={{ boxShadow: 'none', borderRadius: '8px' }}
      >
        Verifique a sua caixa de SPAM. Caso esteja com dificuldades, envie um
        e-mail para suporte@zetaone.com.br
      </Alert>
    </Stack>
  );
}

export default SendEmailConfirm;
