import ErrorIcon from '@mui/icons-material/Error';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import WhatsappDialog, {
  WhatsAppContent,
} from '../../components/WhatsappDialog';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { FormCompanySetup } from '../../types';
import { appActions } from './app-slice';

function CompanySetup() {
  const { isLoading, dbEnterprise } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();

  const defaultValues: FormCompanySetup = {
    enterpriseName: dbEnterprise?.name ?? '',
    enterpriseGender: dbEnterprise?.gender ?? 'a',
    agentName: dbEnterprise?.agent?.name ?? '',
    agentGender: dbEnterprise?.agent?.gender ?? 'a',
  };

  const { register, handleSubmit, formState, watch, reset } =
    useForm<FormCompanySetup>({
      defaultValues,
    });

  const { errors, isDirty } = formState;

  useEffect(() => {
    dispatch(appActions.setIsCurrentFormDirty(isDirty));
  }, [isDirty]);

  const [enterpriseName, enterpriseGender, agentName, agentGender] = watch([
    'enterpriseName',
    'enterpriseGender',
    'agentName',
    'agentGender',
  ]);

  const agentText =
    agentName && agentGender
      ? `*${agentGender} ${agentName}*`
      : '_<agente digital>_';
  const enterpriseText =
    enterpriseName && enterpriseGender
      ? `d${enterpriseGender} *${enterpriseName}*`
      : '_<empresa>_';
  const exampleText = `Olá! Eu sou ${agentText}, agente digital ${enterpriseText}. Tudo bem com você?`;

  const content: WhatsAppContent = {
    contactName: (agentName ?? '') !== '' ? agentName : 'Agente Digital',
    items: [
      {
        type: 'text',
        direction: 'in',
        text: exampleText,
        timestamp: Date.now(),
      },
      {
        type: 'text',
        direction: 'out',
        text: 'Boa noite! Estou bem, obrigado!',
        timestamp: Date.now(),
      },
    ],
  };

  const onSubmit: SubmitHandler<FormCompanySetup> = (data) => {
    dispatch(appActions.saveEnterpriseData(data));
    dispatch(appActions.setIsCurrentFormDirty(false));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction="column" gap={2}>
              <Typography variant="body1">
                Qual é o nome do seu negócio?
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome do Negócio"
              type="text"
              placeholder="Rafael Nutricionista"
              {...register('enterpriseName', {
                required: 'O nome é obrigatório',
                minLength: {
                  value: 2,
                  message: 'O nome é muito curto',
                },
              })}
              error={!!errors.enterpriseName}
              helperText={errors.enterpriseName?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="enterpriseGender">
                Como você se refere ao seu negócio?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="enterpriseGender"
                defaultValue={defaultValues.enterpriseGender}
              >
                <FormControlLabel
                  value="a"
                  control={
                    <Radio
                      {...register('enterpriseGender', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label={`A ${enterpriseName}`}
                />
                <FormControlLabel
                  value="o"
                  control={
                    <Radio
                      {...register('enterpriseGender', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label={`O ${enterpriseName}`}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="column" gap={2}>
              <Typography variant="body1">
                Vamos criar um agente digital para te ajudar com os seus
                pagamentos. Ele irá enviar pelo WhatsApp os lembretes de
                pagamento aos clientes.
              </Typography>
              <Typography variant="body1">
                Agora você vai escolher como ele vai se apresentar:
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nome do Agente Digital"
              type="text"
              placeholder="Zeta"
              {...register('agentName', {
                required: 'O nome é obrigatório',
                minLength: {
                  value: 2,
                  message: 'O nome é muito curto',
                },
              })}
              error={!!errors.agentName}
              helperText={errors.agentName?.message}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="agentGender">
                Como você se refere ao agente digital?
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="agentGender"
                defaultValue={defaultValues.agentGender}
              >
                <FormControlLabel
                  value="a"
                  control={
                    <Radio
                      {...register('agentGender', { required: 'Obrigatório' })}
                    />
                  }
                  label={`A ${agentName}`}
                />
                <FormControlLabel
                  value="o"
                  control={
                    <Radio
                      {...register('agentGender', { required: 'Obrigatório' })}
                    />
                  }
                  label={`O ${agentName}`}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2" color="textSecondary" component="p">
              Exemplo:
            </Typography>
            <WhatsappDialog content={content} />
          </Grid>
        </Grid>

        <Stack direction="row" gap={2} width={1}>
          {isDirty && dbEnterprise && (
            <Stack flexBasis="100%">
              <LoadingButton
                loading={isLoading}
                onClick={() => reset()}
                variant="outlined"
                color="error"
                disabled={!isDirty}
              >
                Descartar
              </LoadingButton>
            </Stack>
          )}
          <Stack flexBasis="100%">
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disabled={!isDirty}
              type="submit"
            >
              {dbEnterprise ? 'Salvar' : 'Próximo'}
            </LoadingButton>
          </Stack>
        </Stack>
        {Object.keys(errors).length > 0 ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <ErrorIcon color="error" />
            <Box paddingLeft={1}>
              <Typography variant="caption" textAlign="center" color="error">
                Por favor corrija os erros antes de prosseguir
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Typography variant="caption" textAlign="center">
            Não se preocupe, você poderá alterar estas informações depois
          </Typography>
        )}
      </Stack>
    </form>
  );
}

export default CompanySetup;
