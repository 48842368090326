'use strict';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Box, Button, Link, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Fragment, ReactNode } from 'react';
import { parseWhatsappMarkdown } from '../helpers/markdown';

const getWapiUrl = () => '';
const getDownloadLink = (x: any) => x;

const fontFamily = [
  'SF Pro Text',
  '"SF Pro Icons"',
  'system',
  '-apple-system',
  'system-ui',
  '"Helvetica Neue"',
  'Helvetica',
  'Arial',
  '"Lucida Grande"',
  '"Kohinoor Devanagari"',
  'sans-serif',
].join(',');

type WhatsAppBubbleDirection = 'in' | 'out' | 'sys';
type WhatsAppContentItem = {
  type: string;
  direction: WhatsAppBubbleDirection;
  text: string;
  timestamp: number;
  accent?: boolean;
  contactName?: string;
  caption?: string;
  file?: any;
  url?: string;
  bubbleBreak?: boolean;
  tail?: boolean;
  showContactName?: boolean;
  contactVcard?: any;
  customContent?: any;
};
export type WhatsAppContent = {
  contactName: string;
  items: WhatsAppContentItem[];
};

const BubbleContainer = ({ children }: { children: ReactNode }) => (
  <Box
    sx={{
      position: 'relative',
      maxWidth: '85%',
    }}
  >
    {children}
  </Box>
);

type BubbleProps = {
  children: ReactNode;
} & WhatsAppContentItem;
const Bubble = ({
  children,
  direction,
  bubbleBreak,
  tail,
  accent,
}: BubbleProps) => (
  <Box
    sx={{
      borderBottomLeftRadius: '7.5px',
      borderBottomRightRadius: '7.5px',
      borderTopLeftRadius: '7.5px',
      borderTopRightRadius: '7.5px',
      fontFamily,
      fontSize: '14.2px',
      fontWeight: '400',
      lineHeight: '19px',
      fontFeatureSettings: '"kern"',
      textRendering: 'optimizelegibility',
      boxShadow: '0 1px .5px rgba(48,48,48,.13)',
      paddingBottom: '8px',
      paddingLeft: '9px',
      paddingRight: '7px',
      paddingTop: '6px',
      marginBottom: '2px',
      ...(direction === 'in'
        ? {
            backgroundColor: 'whatsapp.in',
            color: 'whatsapp.text',
          }
        : {}),
      ...(direction === 'out'
        ? {
            backgroundColor: 'whatsapp.out',
            color: 'whatsapp.text',
          }
        : {}),
      ...(direction === 'sys'
        ? {
            backgroundColor: 'whatsapp.in',
            color: 'whatsapp.sysText',
          }
        : {}),
      ...(bubbleBreak
        ? {
            marginBottom: '12px',
          }
        : {}),
      ...(tail && direction === 'in' ? { borderTopLeftRadius: '0px' } : {}),
      ...(tail && direction === 'out' ? { borderTopRightRadius: '0px' } : {}),
      ...(accent
        ? {
            border: '1px solid',
            borderColor: 'rgba(255, 0, 0, 0.8)',
          }
        : {}),
    }}
  >
    {children}
  </Box>
);

type BubbleTimestampProps = WhatsAppContentItem;
const BubbleTimestamp = ({ timestamp, direction }: BubbleTimestampProps) => (
  <Box
    sx={{
      position: 'relative',
      float: 'right',
      margin: '-10px 0 -5px 4px',
      color: 'whatsapp.timestampText',
      fontFamily,
      fontSize: '11px',
      fontWeight: '400',
      lineHeight: '19px',
      fontFeatureSettings: '"kern"',
      textRendering: 'optimizelegibility',
      height: '15px',
      verticalAlign: 'baseline',
    }}
  >
    <Box
      component="span"
      sx={{
        lineHeight: '15px',
        height: '15px',
        verticalAlign: 'top',
      }}
    >
      {dayjs(timestamp).format('HH:mm')}
    </Box>
    {direction === 'out' && (
      <Box
        sx={{
          marginLeft: '3px',
          display: 'inline-block',
          color: 'whatsapp.readIcon',
          height: '15px',
          lineHeight: '15px',
        }}
      >
        {svgReadIcon}
      </Box>
    )}
  </Box>
);

type BubbleTailProps = WhatsAppContentItem;
const BubbleTail = ({ direction }: BubbleTailProps) => (
  <Box
    component="span"
    sx={{
      ...(direction === 'in'
        ? {
            color: 'whatsapp.in',
            position: 'absolute',
            top: 0,
            display: 'block',
            width: '8px',
            height: '13px',
            left: '-8px',
            fontSize: '0px',
          }
        : {}),
      ...(direction === 'out'
        ? {
            color: 'whatsapp.out',
            position: 'absolute',
            top: 0,
            display: 'block',
            width: '8px',
            height: '13px',
            right: '-8px',
            fontSize: '0px',
          }
        : {}),
    }}
  >
    {direction === 'in' && svgTailIn}
    {direction === 'out' && svgTailOut}
  </Box>
);

type ContactNameProps = WhatsAppContentItem;
const ContactName = ({ contactName }: ContactNameProps) => (
  <Box
    sx={{
      color: 'whatsapp.contactName',
      display: 'inline-flex',
      maxWidth: '100%',
      fontSize: '12.8px',
      fontWeight: '500',
      lineHeight: '22px',
      verticalAlign: 'baseline',
      ...(contactName === 'Administrador'
        ? {
            color: 'whatsapp.contactNameAdm',
          }
        : {}),
    }}
  >
    <Box component="span" flexBasis="auto" flexGrow={0} flexShrink={1}>
      {contactName}
    </Box>
  </Box>
);

type BubbleSpaceProps = WhatsAppContentItem;
const BubbleSpace = ({ direction }: BubbleSpaceProps) => (
  <Box
    component="span"
    sx={{
      ...(direction === 'in'
        ? {
            width: '56px',
            display: 'inline-block',
          }
        : {}),
      ...(direction === 'out'
        ? {
            width: '74px',
            display: 'inline-block',
          }
        : {}),
      ...(direction === 'sys'
        ? {
            width: '74px',
            display: 'inline-block',
          }
        : {}),
    }}
  />
);

// const useStyles = makeStyles((theme) => {
//   //const palette = theme.palette.mode === 'dark' ? darkPalette : lightPalette;
//   const palette = theme.palette.whatsapp;
//   return {
//     bubbleBreak: {
//       marginBottom: '12px',
//     },
//     bubbleTailOut: {
//       borderTopRightRadius: '0px',
//     },
//     bubbleTailIn: {
//       borderTopLeftRadius: '0px',
//     },
//     out: {
//       backgroundColor: palette.out,
//       color: palette.text,
//     },
//     in: {
//       backgroundColor: palette.in,
//       color: palette.text,
//     },
//     sys: {
//       backgroundColor: palette.in,
//       color: palette.sysText,
//     },
//     accent: {
//       border: '1px solid',
//       borderColor: 'rgba(255, 0, 0, 0.8)',
//     },
//     contactName: {
//       color: palette.contactName,
//       display: 'inline-flex',
//       maxWidth: '100%',
//       fontSize: '12.8px',
//       fontWeight: '500',
//       lineHeight: '22px',
//       verticalAlign: 'baseline',
//     },
//     contactNameAdm: {
//       color: palette.contactNameAdm,
//     },
//     bubbleSpaceIn: {
//       width: '56px',
//       display: 'inline-block',
//     },
//     bubbleSpaceOut: {
//       width: '74px',
//       display: 'inline-block',
//     },
//     bubbleSpaceSys: {
//       width: '74px',
//       display: 'inline-block',
//     },
//     tailOut: {
//       color: palette.out,
//       position: 'absolute',
//       top: 0,
//       display: 'block',
//       width: '8px',
//       height: '13px',
//       right: '-8px',
//       fontSize: '0px',
//     },
//     tailIn: {
//       color: palette.in,
//       position: 'absolute',
//       top: 0,
//       display: 'block',
//       width: '8px',
//       height: '13px',
//       left: '-8px',
//       fontSize: '0px',
//     },
//     timestamp: {
//       position: 'relative',
//       float: 'right',
//       margin: '-10px 0 -5px 4px',
//       color: palette.timestampText,
//       fontFamily,
//       fontSize: '11px',
//       fontWeight: '400',
//       lineHeight: '19px',
//       fontFeatureSettings: '"kern"',
//       textRendering: 'optimizelegibility',
//       height: '15px',
//       verticalAlign: 'baseline',
//     },
//     timestampText: {
//       lineHeight: '15px',
//       height: '15px',
//       verticalAlign: 'top',
//     },
//     readIcon: {
//       marginLeft: '3px',
//       display: 'inline-block',
//       color: palette.readIcon,
//       height: '15px',
//       lineHeight: '15px',
//     },
//     fieldExample: {
//       fontWeight: 'bold',
//       borderWidth: '1px',
//     },
//   };
// });

const svgTailIn = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 13"
    width="8"
    height="13"
  >
    <path
      opacity=".13"
      fill="#0000000"
      d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
    ></path>
    <path
      fill="currentColor"
      d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
    ></path>
  </svg>
);
const svgTailOut = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 8 13"
    width="8"
    height="13"
  >
    <path
      opacity=".13"
      d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
    ></path>
    <path
      fill="currentColor"
      d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
    ></path>
  </svg>
);
const svgReadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    width="16"
    height="15"
  >
    <path
      fill="currentColor"
      d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.879a.32.32 0 0 1-.484.033l-.358-.325a.319.319 0 0 0-.484.032l-.378.483a.418.418 0 0 0 .036.541l1.32 1.266c.143.14.361.125.484-.033l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.879a.32.32 0 0 1-.484.033L1.891 7.769a.366.366 0 0 0-.515.006l-.423.433a.364.364 0 0 0 .006.514l3.258 3.185c.143.14.361.125.484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z"
    ></path>
  </svg>
);

// const content = {
//   contactName: 'julia',
//   items: [
//     {
//       direction: 'in',
//       text: 'top',
//       timestamp: 1619435296555,
//     },
//     {
//       direction: 'in',
//       text: 'Oi tudo bem?',
//       timestamp: 1619435304935,
//     },
//     {
//       direction: 'out',
//       text: 'Sim, e você?',
//       timestamp: 1619435312751,
//     },
//   ],
// };
type WhatsappDialogProps = {
  content: WhatsAppContent;
};
function WhatsappDialog({ content }: WhatsappDialogProps) {
  const contentProcessed = content.items.map((item, index, array) => {
    const prev = array[index - 1];
    const next = array[index + 1];
    let more = {};

    if (
      !prev ||
      (prev &&
        (item.direction !== prev.direction ||
          item.contactName !== prev.contactName))
    )
      more = { tail: true, showContactName: true };
    if (next && item.direction !== next.direction)
      more = { ...more, bubbleBreak: true };
    return { ...item, ...more };
  });
  const { contactName } = content;

  return (
    <Box
      sx={{
        backgroundColor: 'whatsapp.background',
        padding: 3,
        borderRadius: 0.5,
      }}
    >
      {contentProcessed.map((item, index) => {
        const {
          direction,
          type,
          text,
          caption,
          file,
          url,
          tail,
          showContactName,
          customContent,
          contactName: itemContactName,
        } = item;
        if (type === 'custom')
          return <Fragment key={index}>{customContent}</Fragment>;
        return (
          <Box
            key={index}
            display="flex"
            flexDirection="row"
            justifyContent={direction === 'sys' ? 'center' : undefined}
          >
            {direction === 'out' && <Box flexGrow={1} />}
            {type === 'sticker' ? (
              <Box
                style={{ maxWidth: '30%', height: 'auto' }}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                <Box>
                  {file ? (
                    <img
                      style={{ maxWidth: '100%', height: 'auto' }}
                      src={`${getWapiUrl()}/files/${file}`}
                    />
                  ) : (
                    <Typography variant="caption">
                      {'<Não foi possível exibir o conteúdo>'}
                    </Typography>
                  )}
                </Box>
                <BubbleContainer>
                  <Bubble {...item}>
                    <Box>&nbsp;</Box>
                    <BubbleTimestamp {...item} />
                  </Bubble>
                </BubbleContainer>
              </Box>
            ) : (
              <BubbleContainer>
                {tail && direction !== 'sys' && <BubbleTail {...item} />}
                <Bubble {...item}>
                  {showContactName && direction === 'in' && (
                    <ContactName
                      {...{
                        ...item,
                        contactName: contactName ?? itemContactName,
                      }}
                    />
                  )}
                  <Box>
                    {type === 'text' &&
                      text.split('\n').map((par, index) => (
                        <Box key={index} component="span">
                          {index > 0 && <br />}
                          {parseWhatsappMarkdown(par).map(
                            ({ text, bold, italics }, indexPar) => (
                              <Box
                                key={indexPar}
                                component="span"
                                style={{
                                  fontWeight: bold ? 'bold' : undefined,
                                  fontStyle: italics ? 'italic' : undefined,
                                }}
                              >
                                {text}
                              </Box>
                            )
                          )}
                        </Box>
                      ))}
                    {type === 'image' && (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                      >
                        {url || file ? (
                          <img
                            style={{ maxWidth: '100%', height: 'auto' }}
                            src={url ?? `${getWapiUrl()}/files/${file}`}
                          />
                        ) : (
                          <Typography variant="caption">
                            {'<Não foi possível exibir o conteúdo>'}
                          </Typography>
                        )}
                        {text ? (
                          <Box marginTop={1}>
                            {text.split('\n').map((par, index) => (
                              <Box key={index} component="span">
                                {index > 0 && <br />}
                                {parseWhatsappMarkdown(par).map(
                                  ({ text, bold, italics }, indexPar) => (
                                    <Box
                                      key={indexPar}
                                      component="span"
                                      style={{
                                        fontWeight: bold ? 'bold' : undefined,
                                        fontStyle: italics
                                          ? 'italic'
                                          : undefined,
                                      }}
                                    >
                                      {text}
                                    </Box>
                                  )
                                )}
                              </Box>
                            ))}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    )}
                    {(type === 'document' || type === 'file') && (
                      <Box display="flex" flexDirection="column">
                        <Box marginBottom={1}>Documento: {caption}</Box>
                        {file ? (
                          <Button
                            color="primary"
                            component={Link}
                            href={
                              file.serverId
                                ? getDownloadLink(file.serverId)
                                : `${getWapiUrl()}/files/${file}`
                            }
                            target="_blank"
                            rel="noopener"
                            endIcon={<GetAppIcon />}
                          >
                            Baixar
                          </Button>
                        ) : (
                          <Box>
                            <Typography variant="caption">
                              {'<Não foi possível exibir o conteúdo>'}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    {(type === 'ptt' || type === 'audio') && (
                      <Box>
                        <audio controls>
                          <source
                            src={`${getWapiUrl()}/files/${file}`}
                            type="audio/ogg"
                          />
                          Your browser does not support the audio element.
                        </audio>
                        {/* <Button
                          color="primary"
                          component={Link}
                          href={`${getWapiUrl()}/files/${file}`}
                          target="_blank"
                          rel="noopener"
                          endIcon={<GetAppIcon />}
                        >
                          Baixar
                        </Button> */}
                      </Box>
                    )}
                    <BubbleSpace {...item} />
                  </Box>
                  <BubbleTimestamp {...item} />
                </Bubble>

                {direction === 'in' && <Box flexGrow={1} />}
              </BubbleContainer>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default WhatsappDialog;
