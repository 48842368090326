import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AppState,
  AuthenticatedUser,
  CheckPix,
  CompleteSignUpData,
  DBEnterprise,
  DBUser,
  FormCompanySetup,
  FormConfirmEmail,
  FormConfirmPassword,
  FormEditUser,
  FormEmailForgotPassword,
  FormEmailLogin,
  FormPixData,
  FormSignUp,
  PixAccountData,
  PixKeyBankData,
  SignupMethod,
} from '../../types';

const initialState: AppState = {
  isInitializing: false,
  isLoading: false,
  isSigningUp: false,
  awaitingAuthProvider: false,
  signupFormType: undefined,
  authProvider: undefined,
  isError: false,
  error: '',
  notifications: [],
  authenticatedUser: undefined,
  dbUser: undefined,
  awaitingPasswordReset: false,
  confirmedPassword: false,
  confirmedEmail: false,
  dbEnterprise: undefined,
  pixKeyBankData: undefined,
  maxStep: 0,
  completedFlow: false,
  loadingButtons: {},
  isCurrentFormDirty: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    reset: () => initialState,
    setIsInitializing: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitializing = payload;
    },
    setError: (state, { payload }: PayloadAction<string>) => {
      state.isError = true;
      state.error = payload;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    finishLoading: (state) => {
      state.isLoading = false;
    },
    signUpWithEmail: (_state, _action: PayloadAction<FormSignUp>) => {},
    signUpWithAuthProvider: (state, _action: PayloadAction<SignupMethod>) => {
      state.awaitingAuthProvider = true;
    },
    loginWithEmailAndPassword: (
      _state,
      _action: PayloadAction<FormEmailLogin>
    ) => {},
    resumeLogin: (_state, _action: PayloadAction<AuthenticatedUser>) => {},
    signOut: (_state, _action: PayloadAction<string | undefined>) => {},
    sendPasswordResetEmail: (
      _state,
      _action: PayloadAction<FormEmailForgotPassword>
    ) => {},
    confirmPassword: (
      _state,
      _action: PayloadAction<FormConfirmPassword>
    ) => {},
    confirmEmail: (_state, _action: PayloadAction<FormConfirmEmail>) => {},
    setAwaitingPasswordReset: (state, { payload }: PayloadAction<boolean>) => {
      state.awaitingPasswordReset = payload;
    },
    setConfirmedPassword: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmedPassword = payload;
    },
    setConfirmedEmail: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmedEmail = payload;
    },
    loginWithAuthProvider: (state, _action: PayloadAction<SignupMethod>) => {
      state.awaitingAuthProvider = true;
    },
    completeSignUp: (_state, _action: PayloadAction<CompleteSignUpData>) => {},
    completeLogin: (_state, _action: PayloadAction<AuthenticatedUser>) => {},
    saveUserData: (_state, _action: PayloadAction<FormEditUser>) => {},
    saveEnterpriseData: (
      _state,
      _action: PayloadAction<FormCompanySetup>
    ) => {},
    saveEnterpriseDataSuccess: () => {},
    saveEnterpriseDataFail: () => {},
    savePixData: (_state, _action: PayloadAction<FormPixData>) => {},
    savePixDataSuccess: () => {},
    savePixDataFail: () => {},
    setSignupFormType: (
      state,
      { payload }: PayloadAction<SignupMethod | undefined>
    ) => {
      state.signupFormType = payload;
      if (!payload) state.awaitingAuthProvider = false;
    },
    setAuthenticatedUser: (
      state,
      { payload }: PayloadAction<AuthenticatedUser | undefined>
    ) => {
      state.authenticatedUser = payload;
      if (payload) state.awaitingAuthProvider = false;
    },
    setAuthProvider: (
      state,
      { payload }: PayloadAction<SignupMethod | undefined>
    ) => {
      state.authProvider = payload;
    },
    setDBUser: (state, { payload }: PayloadAction<DBUser | undefined>) => {
      state.dbUser = payload;
    },
    setDBEnterprise: (
      state,
      { payload }: PayloadAction<DBEnterprise | undefined>
    ) => {
      state.dbEnterprise = payload;
    },
    checkPixKey: (_state, _action: PayloadAction<CheckPix>) => {},
    setPixKeyBankData: (
      state,
      { payload }: PayloadAction<PixKeyBankData | undefined>
    ) => {
      state.pixKeyBankData = payload;
    },
    setMaxStep: (state, { payload }: PayloadAction<number>) => {
      state.maxStep = payload;
    },
    setCompletedFlow: (state, { payload }: PayloadAction<boolean>) => {
      state.completedFlow = payload;
    },
    setIsSigningUp: (state, { payload }: PayloadAction<boolean>) => {
      state.isSigningUp = payload;
    },
    savePixAccountData: (
      _state,
      _action: PayloadAction<PixAccountData & { socialContractFile?: File }>
    ) => {},
    startLoadingButton: (state, { payload }: PayloadAction<string>) => {
      state.loadingButtons[payload] = true;
    },
    stopLoadingButton: (state, { payload }: PayloadAction<string>) => {
      state.loadingButtons[payload] = false;
    },
    getDownloadLink: (
      _state,
      _action: PayloadAction<{
        id: string;
        buttonId: string;
      }>
    ) => {},
    completeFlow: () => {},
    setIsCurrentFormDirty: (state, { payload }: PayloadAction<boolean>) => {
      state.isCurrentFormDirty = payload;
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
