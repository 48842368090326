import GoogleIcon from '@mui/icons-material/Google';
import { SignupMethod } from '../types';
type ProviderIconProps = {
  provider?: SignupMethod;
  fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
};
const ProviderIcon = ({ provider, fontSize }: ProviderIconProps) => {
  switch (provider) {
    case 'google':
      return <GoogleIcon fontSize={fontSize} />;
    default:
      return <></>;
  }
};
export default ProviderIcon;
