import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PasswordStrengthIndicator } from '../../components/PasswordStrengthIndicator';
import Timer from '../../components/Timer';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { testPassword } from '../../helpers/validations';
import { FormConfirmPassword } from '../../types';
import { appActions } from './app-slice';

export type EmailActionType = 'verifyEmail' | 'resetPassword' | undefined;

function EmailAction() {
  const { error, isLoading, confirmedPassword, confirmedEmail } =
    useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const mode = params.get('mode') as EmailActionType;
  const oobCode = params.get('oobCode') ?? '';

  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<FormConfirmPassword>();

  const currentPassword = watch('password', '');
  const passwordStrength = testPassword(currentPassword);

  const onSubmit = (data: FormConfirmPassword) => {
    dispatch(appActions.confirmPassword({ ...data, oobCode }));
  };

  useEffect(() => {
    if (mode === 'verifyEmail') {
      dispatch(appActions.confirmEmail({ oobCode }));
      setTimeout(() => {
        window.location.href = '/';
      }, 5000);
    }
  }, []);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  if (mode === 'verifyEmail')
    return (
      <Stack direction="column" gap={2}>
        {confirmedEmail ? (
          <>
            <Typography variant="body1" textAlign="center">
              O seu e-mail foi validado com sucesso.
            </Typography>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ '& img': { maxWidth: '100px' } }}
            >
              <img src="images/check.svg" alt="Logo" />
            </Box>
            <Typography variant="body1" textAlign="center">
              Você será redirecionado automaticamente, caso contrário clique no
              botão abaixo
            </Typography>
            <Typography variant="body1" textAlign="center">
              Redirecionando em <Timer timerValueSeconds={5} /> segundos...
            </Typography>
            <Button variant="outlined" color="secondary" href="/">
              Clique aqui para continuar
            </Button>
          </>
        ) : (
          <>
            <Stack direction="column" gap={2} alignItems="center">
              <Typography variant="body1" textAlign="center">
                Validando...
              </Typography>
              <CircularProgress />
            </Stack>
          </>
        )}
      </Stack>
    );
  if (mode === 'resetPassword')
    return (
      <Stack direction="column" gap={2}>
        {confirmedPassword ? (
          <>
            <Typography variant="body1" textAlign="center">
              Senha alterada.
            </Typography>
            <Box
              p={2}
              display="flex"
              justifyContent="center"
              sx={{ '& img': { maxWidth: '100px' } }}
            >
              <img src="images/check.svg" alt="Logo" />
            </Box>
            <Typography variant="body1" textAlign="center">
              Clique no botão abaixo para fazer o login
            </Typography>
            <Button variant="outlined" color="secondary" href="/login">
              Fazer Login
            </Button>
          </>
        ) : (
          <>
            <Typography variant="body1" textAlign="center">
              Escolha a nova senha:
            </Typography>
            {error !== '' && (
              <Typography variant="body1" textAlign="center" color="error">
                {error}
              </Typography>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Stack direction="column" gap={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Senha"
                      type={showPassword ? 'text' : 'password'}
                      autoComplete="new-password"
                      placeholder="*****"
                      {...register('password', {
                        validate: (value) => {
                          const result = testPassword(value);
                          if (result.strength !== 'strong') return false;
                          return true;
                        },
                      })}
                      error={!!errors.password || !!errors.passwordRepeat}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="ocultar ou exibir a senha"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Confirme a senha"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="*****"
                      autoComplete="new-password"
                      {...register('passwordRepeat', {
                        required: 'Digite novamente a senha para confirmar',
                        validate: (value) => {
                          return (
                            value === currentPassword ||
                            'As duas senhas não conferem'
                          );
                        },
                      })}
                      error={!!errors.password || !!errors.passwordRepeat}
                      helperText={
                        errors.passwordRepeat
                          ? errors.passwordRepeat?.message ?? 'Senha inválida'
                          : ''
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              tabIndex={-1}
                              aria-label="ocultar ou exibir a senha"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PasswordStrengthIndicator
                      passwordStrength={passwordStrength}
                    />
                  </Grid>
                </Grid>
                <LoadingButton
                  type="submit"
                  loading={isLoading}
                  onClick={() => {}}
                  variant="contained"
                >
                  Enviar
                </LoadingButton>
              </Stack>
            </form>
          </>
        )}
      </Stack>
    );
  return <></>;
}

export default EmailAction;
