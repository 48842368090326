import { DBEnterprise, DBUser } from '../types';
import { auth } from './firebase';

export const steps = [
  '/',
  '/company-setup',
  '/pix-setup',
  '/account-setup',
  '/finish',
];

export const getCurrentPathnameForData = (
  dbUser?: DBUser,
  dbEnterprise?: DBEnterprise
) => {
  if (!auth.currentUser) throw new Error('Unexpected empty auth.currentUser');
  const flowCompleted = dbEnterprise?.flowCompleted;
  const completedLoginSignup = !!dbUser;
  const completedCompanySetup = !!dbEnterprise;
  const completedPixSetup = !!dbEnterprise?.pixKeyDestination;
  const completedPixAccountSetup = !!dbEnterprise?.pixAccountData;
  const completedEmailValidation = auth.currentUser.emailVerified;
  const companyActive = dbEnterprise?.isActive;

  let pathname = '';

  if (companyActive) pathname = '/finish';
  else if (flowCompleted) pathname = '/active';
  else if (completedPixAccountSetup && completedEmailValidation) {
    pathname = '/finish';
  } else if (completedPixAccountSetup && !completedEmailValidation) {
    pathname = '/send-email-confirm';
  } else if (completedPixSetup) {
    pathname = '/account-setup';
  } else if (completedCompanySetup) {
    pathname = '/pix-setup';
  } else if (completedLoginSignup) {
    pathname = '/company-setup';
  } else pathname = '/login';

  return pathname;
};
export const getStepsForPath = (pathname: string) => {
  switch (pathname) {
    case '/':
    case '/login':
      return 0;
    case '/company-setup':
      return 1;
    case '/pix-setup':
      return 2;
    case '/account-setup':
      return 3;
    case '/finish':
      return 4;
  }
  return 0;
};
