import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { PasswordStrengthIndicator } from '../../components/PasswordStrengthIndicator';
import { PhoneConsentText } from '../../components/PhoneConsent';
import { PhoneInput } from '../../components/PhoneInput';
import { useAppSelector } from '../../helpers/hooks';
import { testEmail, testPassword } from '../../helpers/validations';
import { FormSignUp } from '../../types';
import SignUpButton from './SignUpButton';

type SignUpEmailFormProps = {
  onSubmit: (data: FormSignUp) => void;
};
const SignUpEmailForm = ({ onSubmit }: SignUpEmailFormProps) => {
  const { error } = useAppSelector((state) => state.app);
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
  } = useForm<FormSignUp>();

  const currentPassword = watch('password', '');
  const passwordStrength = testPassword(currentPassword);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmitClick = (data: FormSignUp) => {
    onSubmit(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmitClick)}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {error !== '' && (
        <Typography variant="body1" textAlign="center" color="error">
          {error}
        </Typography>
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Nome"
            type="text"
            placeholder="Nome"
            {...register('displayName', {
              required: 'O nome é obrigatório',
              minLength: 5,
            })}
            error={!!errors.displayName}
            helperText={errors.displayName?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="E-mail"
            type="text"
            placeholder="zeta@acme.com.br"
            {...register('email', {
              validate: (v) => testEmail(v) || 'Digite um e-mail válido',
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneInput
            type="text"
            placeholder="(00) 00000-0000"
            fullWidth
            label="Telefone Celular"
            name="phoneNumber"
            control={control}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <Tooltip
            //         title={<WhatsAppInfo />}
            //         open={isWhatsappInfoOpen}
            //       >
            //         <InfoIcon
            //           sx={{ cursor: 'pointer' }}
            //           onMouseEnter={() => setIsWhatsappInfoOpen(true)}
            //           onMouseLeave={() => setIsWhatsappInfoOpen(false)}
            //           onClick={() => setIsWhatsappInfoOpen(true)}
            //         />
            //       </Tooltip>
            //     </InputAdornment>
            //   ),
            // }}
          />
        </Grid>
        <Grid item xs={12}>
          <PhoneConsentText />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            autoComplete="new-password"
            placeholder="*****"
            {...register('password', {
              validate: (value) => {
                const result = testPassword(value);
                if (result.strength !== 'strong') return false;
                return true;
              },
            })}
            error={!!errors.password || !!errors.passwordRepeat}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="ocultar ou exibir a senha"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Confirme a senha"
            type={showPassword ? 'text' : 'password'}
            placeholder="*****"
            autoComplete="new-password"
            {...register('passwordRepeat', {
              required: 'Digite novamente a senha para confirmar',
              validate: (value) => {
                return (
                  value === currentPassword || 'As duas senhas não conferem'
                );
              },
            })}
            error={!!errors.password || !!errors.passwordRepeat}
            helperText={
              errors.passwordRepeat
                ? errors.passwordRepeat?.message ?? 'Senha inválida'
                : ''
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    tabIndex={-1}
                    aria-label="ocultar ou exibir a senha"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordStrengthIndicator passwordStrength={passwordStrength} />
        </Grid>
      </Grid>
      <SignUpButton onClick={handleSubmit(onSubmitClick)} />
    </form>
  );
};

export default SignUpEmailForm;
