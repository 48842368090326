import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SubmitHandler } from 'react-hook-form/dist/types';
import { ButtonGroupInput } from '../../components/ButtonGroupInput';
import { CNPJInput } from '../../components/CNPJInput';
import { CPFInput } from '../../components/CPFInput';
import { PhoneInput } from '../../components/PhoneInput';
import { formatValue } from '../../helpers/formatter';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { getPixRate } from '../../helpers/pix-rate';
import {
  testCNPJ,
  testCPF,
  testEmail,
  testPhone,
} from '../../helpers/validations';
import { CompanySize, FormPixData, FormPixSetup, Plan } from '../../types';
import { appActions } from './app-slice';

const getPixKeyFromFormData = (data: FormPixSetup) => {
  let pixKeyDestination: string | undefined;
  switch (data.pixKeyType) {
    case 'CNPJ':
      pixKeyDestination = (data.cnpjPixKey ?? '').replace(/[^\d+]+/g, '');
      break;
    case 'CPF':
      pixKeyDestination = (data.cpfPixKey ?? '').replace(/[^\d+]+/g, '');
      break;
    case 'EMAIL':
      pixKeyDestination = data.emailPixKey ?? '';
      break;
    case 'EVP':
      pixKeyDestination = data.evpPixKey ?? '';
      break;
    case 'PHONE':
      pixKeyDestination =
        '55' + (data.phonePixKey ?? '').replace(/[^\d+]+/g, '');
      break;
    default:
      pixKeyDestination = undefined;
  }
  return pixKeyDestination;
};

const VerifiedIcon = ({ isKeyVerified }: { isKeyVerified: boolean }) => (
  <>
    {isKeyVerified ? (
      <CheckCircleIcon color="success" />
    ) : (
      <WarningIcon color="warning" />
    )}
  </>
);

function PixSetup() {
  const { isLoading, dbEnterprise, dbUser } = useAppSelector(
    (state) => state.app
  );

  const defaultPixKeyType = dbEnterprise?.pixKeyDestinationType ?? 'CPF';
  const defaultPlan = dbEnterprise?.plan ?? dbUser?.plan ?? 'lite';
  const defaultCompanySize: CompanySize =
    dbEnterprise?.companySize ??
    (defaultPlan === 'business' ? '51-100' : '1-50');

  const defaultValues: FormPixSetup = {
    pixKeyType: defaultPixKeyType,
    cpfPixKey:
      dbEnterprise?.pixKeyDestinationType === 'CPF'
        ? dbEnterprise?.pixKeyDestination
        : '',
    cnpjPixKey:
      dbEnterprise?.pixKeyDestinationType === 'CNPJ'
        ? dbEnterprise?.pixKeyDestination
        : '',
    emailPixKey:
      dbEnterprise?.pixKeyDestinationType === 'EMAIL'
        ? dbEnterprise?.pixKeyDestination
        : '',
    phonePixKey:
      dbEnterprise?.pixKeyDestinationType === 'PHONE'
        ? formatValue(dbEnterprise?.pixKeyDestination, 'phone')
        : '',
    evpPixKey:
      dbEnterprise?.pixKeyDestinationType === 'EVP'
        ? dbEnterprise?.pixKeyDestination
        : '',
    companySize: defaultCompanySize,
  };

  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
    reset,
  } = useForm<FormPixSetup>({ defaultValues });

  useEffect(() => {
    dispatch(appActions.setIsCurrentFormDirty(isDirty));
  }, [isDirty]);

  const pixKeyType = watch('pixKeyType', defaultPixKeyType);
  const companySize = watch('companySize');
  const plan: Plan = companySize === '1-50' ? 'lite' : 'business';

  const onSubmit: SubmitHandler<FormPixSetup> = (data) => {
    const pixKeyDestination = getPixKeyFromFormData(data);
    if (
      // !pixKeyBankData ||
      !data.pixKeyType ||
      !pixKeyDestination ||
      !data.companySize
    )
      throw new Error('Erro salvando dados');

    const plan: Plan = data.companySize === '1-50' ? 'lite' : 'business';

    const dataToSave: FormPixData = {
      pixKeyDestinationType: data.pixKeyType,
      pixKeyDestination,
      plan: plan,
      pixRate: getPixRate(plan),
      companySize: data.companySize,
    };
    dispatch(appActions.savePixData(dataToSave));
    dispatch(appActions.setIsCurrentFormDirty(false));
  };

  const companySizeOptions = [
    { label: '1-50', value: '1-50' },
    { label: '51-100', value: '51-100' },
    { label: '101-200', value: '101-200' },
    { label: '200+', value: '200+' },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" gap={2}>
        <Typography variant="body1">
          Agora vamos configurar a seleção do seu plano e o pagamento.
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">
              Quantas cobranças por mês você pretende fazer?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ButtonGroupInput
              name="companySize"
              control={control}
              options={companySizeOptions}
            />
          </Grid>
          <Grid item xs={12}>
            {plan === 'lite' && (
              <Card variant="outlined">
                <Box p={2}>
                  <Typography variant="h5" gutterBottom>
                    Plano <strong>Lite</strong>
                  </Typography>
                  <Typography>
                    <strong>{formatValue(getPixRate(plan), 'money')}</strong>{' '}
                    por PIX recebido
                  </Typography>
                  <Typography>
                    Até <strong>50</strong> PIX/mês
                  </Typography>
                  <Typography>Sem volume mínimo</Typography>
                </Box>
              </Card>
            )}
            {plan === 'business' && (
              <Card variant="outlined">
                <Box p={2}>
                  <Typography variant="h5" gutterBottom>
                    Plano <strong>Business</strong>
                  </Typography>
                  <Typography>
                    <strong>{formatValue(getPixRate(plan), 'money')}</strong>{' '}
                    por PIX recebido
                  </Typography>
                  <Typography>
                    Até <strong>1000</strong> PIX/mês
                  </Typography>
                  <Typography>
                    Mínimo de <strong>50</strong> PIX/mês
                  </Typography>
                </Box>
              </Card>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Também precisamos saber a conta de recebimento, isto é, para onde
              os pagamentos dos seus clientes serão enviados diariamente.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              Este envio é feito via PIX para o banco da sua escolha, por isso
              precisamos da sua chave PIX.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="pixKeyType">Qual o tipo de chave PIX?</FormLabel>
              <RadioGroup
                aria-labelledby="pixKeyType"
                defaultValue={defaultPixKeyType}
              >
                <FormControlLabel
                  value="CPF"
                  control={
                    <Radio
                      {...register('pixKeyType', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label="CPF"
                />
                <FormControlLabel
                  value="CNPJ"
                  control={
                    <Radio
                      {...register('pixKeyType', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label="CNPJ"
                />
                <FormControlLabel
                  value="EMAIL"
                  control={
                    <Radio
                      {...register('pixKeyType', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label="E-mail"
                />
                <FormControlLabel
                  value="PHONE"
                  control={
                    <Radio
                      {...register('pixKeyType', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label="Telefone"
                />
                <FormControlLabel
                  value="EVP"
                  control={
                    <Radio
                      {...register('pixKeyType', {
                        required: 'Obrigatório',
                      })}
                    />
                  }
                  label="Chave Aleatória"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{ display: pixKeyType === 'CPF' ? 'default' : 'none' }}
          >
            <CPFInput
              type="text"
              placeholder="000.000.000-00"
              fullWidth
              label="Chave PIX - CPF"
              name="cpfPixKey"
              customValidate={(v, { pixKeyType }) => {
                if (pixKeyType !== 'CPF') return true;
                return testCPF(v) || 'Digite um CPF válido';
              }}
              control={control}
              error={!!errors.cpfPixKey}
              helperText={errors.cpfPixKey?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerifiedIcon isKeyVerified={!errors.cpfPixKey} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: pixKeyType === 'CNPJ' ? 'default' : 'none' }}
          >
            <CNPJInput
              type="text"
              placeholder="00.000.000/0000-00"
              fullWidth
              label="Chave PIX - CNPJ"
              name="cnpjPixKey"
              customValidate={(v, { pixKeyType }) => {
                if (pixKeyType !== 'CNPJ') return true;
                return testCNPJ(v) || 'Digite um CNPJ válido';
              }}
              control={control}
              error={!!errors.cnpjPixKey}
              helperText={errors.cnpjPixKey?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerifiedIcon isKeyVerified={!errors.cnpjPixKey} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: pixKeyType === 'EMAIL' ? 'default' : 'none' }}
          >
            <TextField
              fullWidth
              label="Chave PIX - E-mail"
              type="text"
              placeholder="zeta@acme.com.br"
              {...register('emailPixKey', {
                validate: (v, { pixKeyType }) => {
                  if (pixKeyType !== 'EMAIL') return true;
                  return testEmail(v) || 'Digite um e-mail válido';
                },
              })}
              error={!!errors.emailPixKey}
              helperText={errors.emailPixKey?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerifiedIcon isKeyVerified={!errors.emailPixKey} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: pixKeyType === 'PHONE' ? 'default' : 'none' }}
          >
            <PhoneInput
              type="text"
              placeholder="(00) 00000-0000"
              fullWidth
              label="Chave PIX - Telefone"
              name="phonePixKey"
              customValidate={(v, { pixKeyType }) => {
                if (pixKeyType !== 'PHONE') return true;
                return testPhone(v) || 'Telefone inválido';
              }}
              control={control}
              error={!!errors.phonePixKey}
              helperText={errors.phonePixKey?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerifiedIcon isKeyVerified={!errors.phonePixKey} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: pixKeyType === 'EVP' ? 'default' : 'none' }}
          >
            <TextField
              fullWidth
              label="Chave PIX - Aleatória"
              type="text"
              placeholder="0000000000"
              {...register('evpPixKey', {
                validate: (v, { pixKeyType }) => {
                  if (pixKeyType !== 'EVP') return true;
                  return (v ?? '') !== '' || 'Digite um e-mail válido';
                },
              })}
              error={!!errors.evpPixKey}
              helperText={errors.evpPixKey?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <VerifiedIcon isKeyVerified={!errors.evpPixKey} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* {pixKeyBankData && (
            <>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    p={2}
                  >
                    <Avatar
                      sx={{
                        width: 48,
                        height: 48,
                        backgroundColor: 'secondary.light',
                      }}
                    >
                      <PersonIcon fontSize="large" />
                    </Avatar>
                    <Box paddingLeft={2}>
                      <Typography variant="body2">
                        {pixKeyBankData?.ownerName}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="span"
                      >
                        {pixKeyBankData?.cpfCnpj}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {pixKeyBankData?.bankName}
                      </Typography>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </>
          )} */}
        </Grid>

        <Stack direction="row" gap={2} width={1}>
          {isDirty && dbEnterprise?.pixKeyDestination && (
            <Stack flexBasis="100%">
              <LoadingButton
                loading={isLoading}
                onClick={() => reset()}
                variant="outlined"
                color="error"
                disabled={!isDirty}
              >
                Descartar
              </LoadingButton>
            </Stack>
          )}
          <Stack flexBasis="100%">
            <LoadingButton
              loading={isLoading}
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              disabled={!isDirty}
              type="submit"
            >
              {dbEnterprise?.pixKeyDestination ? 'Salvar' : 'Próximo'}
            </LoadingButton>
          </Stack>
        </Stack>

        {Object.keys(errors).length > 0 ? (
          <Stack direction="row" alignItems="center" justifyContent="center">
            <ErrorIcon color="error" />
            <Box paddingLeft={1}>
              <Typography variant="caption" textAlign="center" color="error">
                Por favor corrija os erros antes de prosseguir
              </Typography>
            </Box>
          </Stack>
        ) : (
          <Typography variant="caption" textAlign="center">
            Não se preocupe, você poderá alterar estas informações depois
          </Typography>
        )}
      </Stack>
    </form>
  );
}

export default PixSetup;
