'use strict';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export type TimerProps = {
  timerValueSeconds: number;
};
export default function Timer({ timerValueSeconds }: TimerProps) {
  const [date] = useState(new Date());
  const [countDown, setCountDown] = useState(timerValueSeconds);

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setCountDown(timerValueSeconds - dayjs().diff(date, 'seconds'));
  }

  return <>{countDown}</>;
}
