import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import GoogleLogo from '../../components/GoogleLogo';
import ProviderIcon from '../../components/ProviderIcon';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { testEmail } from '../../helpers/validations';
import { FormEmailLogin } from '../../types';
import { appActions } from './app-slice';

function Login() {
  const {
    error,
    signupFormType,
    awaitingAuthProvider,
    authenticatedUser,
    authProvider,
    isLoading,
    isInitializing,
  } = useAppSelector((state) => state.app);
  const dispatch = useAppDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormEmailLogin>();

  const onSubmit = (data: FormEmailLogin) => {
    dispatch(appActions.loginWithEmailAndPassword(data));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Stack direction="column" gap={2}>
      {isInitializing ? (
        <>
          <Stack direction="column" gap={2} alignItems="center">
            <Typography variant="body1" textAlign="center">
              Iniciando aplicação
            </Typography>
            <CircularProgress />
          </Stack>
        </>
      ) : (
        <>
          {awaitingAuthProvider ? (
            <>
              <Stack direction="column" gap={2} alignItems="center">
                <Typography variant="body1" textAlign="center">
                  Uma janela pop-up foi aberta para que você possa entrar de
                  forma segura com o provedor.
                </Typography>
                <Typography variant="body1" textAlign="center">
                  Estamos aguardando a sua autorização.
                </Typography>
                <ProviderIcon provider={authProvider} fontSize="large" />
              </Stack>
            </>
          ) : (
            <>
              <Typography variant="body1" textAlign="center">
                Escolha como quer entrar:
              </Typography>

              <LoadingButton
                variant="outlined"
                onClick={() =>
                  dispatch(appActions.loginWithAuthProvider('google'))
                }
                sx={{
                  padding: '12px',
                  height: '40px',
                  color: 'rgba(0,0,0,0.54)',
                  fontFamily: 'roboto',
                  fontSize: '14px',
                  maxWidth: '400px',
                  marginX: 'auto',
                  fontWeight: 500,
                  borderColor: '#dadce0',
                  [':hover']: {
                    borderColor: '#d2e3fc',
                    backgroundColor: 'rgba(66,133,244,.04)',
                  },
                }}
              >
                <GoogleLogo />
                <Box marginLeft="24px" flexGrow={1}>
                  Fazer login com o Google
                </Box>
              </LoadingButton>
              <Divider />
              <Typography variant="body1" textAlign="center">
                Ou entrar utilizando e-mail e senha:
              </Typography>
              {error !== '' && (
                <Typography variant="body1" textAlign="center" color="error">
                  {error}
                </Typography>
              )}
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack direction="column" gap={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="E-mail"
                        type="text"
                        placeholder="zeta@acme.com.br"
                        {...register('email', {
                          validate: (v) =>
                            testEmail(v) || 'Digite um e-mail válido',
                        })}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Senha"
                        type={showPassword ? 'text' : 'password'}
                        autoComplete="new-password"
                        placeholder="*****"
                        {...register('password', {
                          required: 'Senha obrigatória',
                        })}
                        error={!!errors.password}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                tabIndex={-1}
                                aria-label="ocultar ou exibir a senha"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                  <LoadingButton
                    type="submit"
                    loading={isLoading}
                    variant="contained"
                  >
                    Login
                  </LoadingButton>
                </Stack>
              </form>
              <Button variant="outlined" href="/">
                Ainda não possuo uma conta
              </Button>
              <Button
                variant="outlined"
                href="/password-reset"
                endIcon={<HelpOutlineIcon />}
              >
                Esqueci minha senha
              </Button>
            </>
          )}
        </>
      )}
    </Stack>
  );
}

export default Login;
