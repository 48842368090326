import { LoadingButton } from '@mui/lab';
import { Box, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import { testEmail } from '../../helpers/validations';
import { FormEmailForgotPassword } from '../../types';
import { appActions } from './app-slice';

function SendPasswordReset() {
  const { error, isLoading, awaitingPasswordReset } = useAppSelector(
    (state) => state.app
  );
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormEmailForgotPassword>();

  const onSubmit = (data: FormEmailForgotPassword) => {
    dispatch(appActions.sendPasswordResetEmail(data));
  };

  return (
    <Stack direction="column" gap={2}>
      {awaitingPasswordReset ? (
        <>
          <Typography variant="body1" textAlign="center">
            E-mail enviado!
          </Typography>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
            sx={{ '& img': { maxWidth: '150px' } }}
          >
            <img src="images/check.svg" alt="Logo" />
          </Box>
          <Typography variant="body1" textAlign="center">
            Verifique sua caixa de entrada, um e-mail foi enviado para que você
            possa reiniciar sua senha.
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="body1" textAlign="center">
            Digite o seu e-mail:
          </Typography>
          {error !== '' && (
            <Typography variant="body1" color="error">
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack direction="column" gap={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="E-mail"
                    type="text"
                    placeholder="zeta@acme.com.br"
                    {...register('email', {
                      validate: (v) =>
                        testEmail(v) || 'Digite um e-mail válido',
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                </Grid>
              </Grid>
              <Box textAlign="center">
                <Typography variant="caption">
                  Ao clicar em ENVIAR, enviaremos um e-mail para que você possa
                  reiniciar sua senha
                </Typography>
              </Box>
              <LoadingButton
                type="submit"
                loading={isLoading}
                variant="contained"
              >
                Enviar
              </LoadingButton>
            </Stack>
          </form>

          <Button href="/login">Voltar</Button>
        </>
      )}
    </Stack>
  );
}

export default SendPasswordReset;
