import { Box, Stack, Typography } from '@mui/material';

function Pending() {
  return (
    <Stack direction="column" justifyContent="center" p={4} gap={2}>
      <Typography variant="body1" textAlign="center">
        Conta criada com sucesso!
      </Typography>
      <Box
        p={2}
        display="flex"
        justifyContent="center"
        sx={{ '& img': { maxWidth: '150px' } }}
      >
        <img src="images/check.svg" alt="Logo" />
      </Box>
      <Typography variant="h5" textAlign="center">
        Falta pouco!
      </Typography>
      <Typography variant="body1" textAlign="center">
        A sua conta está em revisão.
      </Typography>
      <Typography variant="body1" textAlign="center">
        Você receberá uma notificação por e-mail assim que a conta for ativada!
      </Typography>
    </Stack>
  );
}

export default Pending;
