import { ThemeOptions, createTheme } from '@mui/material';
import { ptBR } from '@mui/material/locale';
import _ from 'lodash';

declare module '@mui/material/styles' {
  interface PaletteOptions {
    whatsapp: {
      shadow: string;
      background: string;
      out: string;
      in: string;
      contactName: string;
      contactNameAdm: string;
      check: string;
      text: string;
      sysText: string;
      timestampText: string;
      readIcon: string;
    };
  }
}

const baseTheme: ThemeOptions = {
  // overrides: {
  //   MuiCssBaseline: {
  //     '@global': {
  //       // html: {
  //       //   WebkitFontSmoothing: undefined,
  //       //   MozOsxFontSmoothing: undefined,
  //       // },
  //       body: {
  //         margin: 0,
  //         padding: 0,
  //       },
  //     },
  //   },
  // },
  typography: {
    fontFamily: ['poppins', 'sans-serif'].join(','),
  },
  palette: {
    primary: {
      main: '#fdb913',
    },
    secondary: {
      main: '#662d91',
    },
    success: {
      main: '#b6cd73',
    },
    error: {
      main: '#e53c58',
    },
    warning: {
      main: '#f7ac6f',
    },
    text: {
      primary: 'rgba(102, 45, 145, 0.87)',
      secondary: 'rgba(102, 45, 145, 0.6)',
      disabled: 'rgba(102, 45, 145, 0.38)',
    },
    divider: 'rgba(102, 45, 145, 0.12)',
    whatsapp: {
      shadow: '#303030',
      background: '#DFD5CC',
      out: '#D4F9BA',
      in: '#FFFFFF',
      contactName: '#31C383',
      contactNameAdm: '#C331C3',
      check: '#34B7F1',
      text: '#000000',
      sysText: 'rgba(100, 150, 200)',
      timestampText: 'rgba(0, 0, 0, 0.45)',
      readIcon: '#4fc3f7',
    },
  },
  shape: {
    borderRadius: 32,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: '0px 0px 0px 0px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation: {
          boxShadow: `
            3.8px 3.1px 7.9px rgba(0, 0, 0, 0.073),
            13.8px 11px 21.9px rgba(0, 0, 0, 0.108),
            39.1px 31.1px 52.8px rgba(0, 0, 0, 0.142),
            138px 110px 175px rgba(0, 0, 0, 0.23)
          `,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(166, 138, 189, 0.92)',
        },
      },
    },
  },
};

const lightThemeOptions = {};

const darkThemeOptions = {};

export const lightTheme = createTheme(
  _.merge(baseTheme, lightThemeOptions),
  ptBR
);
export const darkTheme = createTheme(
  _.merge(baseTheme, darkThemeOptions),
  ptBR
);
